import Bike from './Bike.js';
import ChosenBike from './ChosenBike.js';
import { useState, useEffect } from 'react';


const ViewBikes = ({ user, setUser, sendEmail, logout }) => {

  let [selectedBike, setSelectedBike] = useState();
  const [pickupSlots, setPickupSlots] = useState()
  let [bikes, setBikes] = useState([]);
  useEffect(() => {
    loadBikes();
  }, []);

  function loadBikes() {
    let data = { email: 'all' };
    fetch("/getAllBikesNoPics", {
      method: "POST",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" }
    })
      .then(res => {
        let response = res;
        //console.log(response);
        return (response.json());
      })
      .then(response => {
        let local = response;
        //console.log(local);
        setBikes(local);
      })

      .catch(
        e => {
          //console.log("Error:" + e)
          alert("There was an error connecting to our server to load the bikes. Please logout, login and try again. Error: " + e)
          logout()
        }

      )


  }

  useEffect(() => {
    loadPickupSlots();
  }, []);

  useEffect(() => {
    loadPickupSlots();
  }, [selectedBike]);

  function loadPickupSlots() {
    // Fetch all locations, then fetch all pickup slots
    fetch("/getAllLocations")
      .then(res => {
        return res.json();
      })
      .then(res => {
        let locations = res;
        fetch("/getAllPickupSlots")
          .then(res => {
            return res.json();
          })
          .then(res => {
            let pickupSlots = res;
            let locationSlots = [];
            for (let i = 0; i < locations.length; i++) {
              locationSlots.push(locations[i]);
              locationSlots[i].pickup_slots = [];
            }
            for (let i = 0; i < locations.length; i++) {
              for (let j = 0; j < pickupSlots.length; j++) {
                if (pickupSlots[j].location_id === locations[i].id) {
                  locationSlots[i].pickup_slots.push(pickupSlots[j]);
                }
              }
            }
            setPickupSlots(locationSlots);
          })
      })
      .catch(
        e => {
          //console.log("Error fetching pickup slots:" + e)
          alert("There was an error connecting to our server to load the pickup slots. Please logout, login and try again. Error: " + e)
          logout()
        }
      )

  }

  let bikesFiltered = bikes.filter(bike => {
    //2023 -> Removed the test for males:
    let testSize = bike.size == user.bike_size
    let testStatus = bike.status == "Available"
    //convert user.priority to integer:
    let testPriority = (parseInt(user.priority)>0)
    console.log('Priority:',user.priority)
    return (testSize && testStatus && testPriority)
  });

  //console.log('Bikes Filtered:',bikesFiltered);

  return (
    
    user.bike_id && Object.keys(bikes).length > 0 ?
      <>
        <h3>Chosen Bike</h3>
        <div className="chosenBike">
          <ChosenBike user={user} setUser={setUser} key="chosenBike" bikes={bikes} pickupSlots={pickupSlots} sendEmail={sendEmail} />
        </div>
      </>
      : Object.keys(bikes).length > 0 ?
        Object.keys(bikesFiltered).length > 0 ?
          <div className="wrapper">

            <h3>Available Bikes in Your Size</h3>
            <div className="wrapper">
              <p> See a bike you like? Is it at a location you can go to? Then click on the RESERVE button. You’ll be asked to choose a time on the designated pick-up day to come and get your bike.</p>
              <p>RESERVE button not working? Please contact each bikehub to reserve this bike.</p>
              {/* {user.gender === "Female" ?
                <p>Not seeing something you like? You can check for more options later. Also, we will email you when more bikes in your size are available. Remember to check your email often so you do not miss out.</p>
                : <><p>Not seeing something you like? You can check for more options later. </p></>
              } */}
              <p>Not seeing something you like? You can check for more options later. </p>
            </div>
            <div className="bikeCatalog wrapper">
              {bikesFiltered.map(bike => {
                let uniqueKey = "bike" + bike.id;
                return (<Bike user={user} setUser={setUser} key={uniqueKey} bike={bike} pickupSlots={pickupSlots} selectedBike={selectedBike} setSelectedBike={setSelectedBike} sendEmail={sendEmail} />);
              })
              }
            </div>
          </div> : <><h3>Available Bikes in Your Size</h3>
            <div className="centeredP wrapper">
              {/* {user.gender === "Female" ?
                <p> There are no bikes in your size ({user.bike_size}) at the moment. You can check for more options later. Also, we will email you when more bikes in your size are available. Remember to check your email often so you do not miss out.</p> :
                <>
                  <p>At BikeMatchWCN, our priority is getting bikes to those who identify as female. </p>
                  <p>For this reason, when bikes are included they are shown first to those who identify as female.</p>
                  <p>After {delay} days, the bike becomes available for the general public.</p>
                  <p>Please check back in a couple of days, when you may be able to choose from bikes available in your size.</p></>
                  } */}
              <p>All the bikes in your size ({user.bike_size}) have been reserved.</p>
              <p>We will email you when more bikes in your size are available.</p>
              <p>Remember to check your email often so you do not miss out.</p>


            </div>
          </>
        : <h3>Loading bikes...</h3>

  )

}
export default ViewBikes;