import Bike from './Bike.js';
import { useState, useEffect } from 'react';

const ViewBikesAdmin = ({ user, setUser, locationsDateTimes, logout }) => {

    let [selectedBike, setSelectedBike] = useState();
    let [filter, setFilter] = useState('na');
    function changeFilter(event) {
        //console.log(event.target.value)
        setFilter(event.target.value)
    }

    let [bikes, setBikes] = useState([]);
    useEffect(() => {
        loadBikes();
    }, [filter]);


    function loadBikes() {
        let data = { email: 'all', status: filter };
        //fetch("/getBikesPerStatusAndUsers",{
        fetch("/getAllBikesAndUsersNoPics", {

            method: "POST",
            body: JSON.stringify(data),
            headers: { "Content-Type": "application/json" }
        })
            .then(res => {
                let response = res;
                //console.log(response);
                return (response.json());
            })
            .then(response => {
                let final = response;
                //console.log(final);
                setBikes(final);
            })

            .catch(e => {
                //console.log("Error:" + e)
                alert("There was an error connecting to our server to load the bikes and users. Please logout, login and try again. Error: " + e)
                logout()
            }
            )


    }



    return (

        <>
            <div>
                <form className="wrapper">
                    <label htmlFor="filterBike">Choose a bike status (you can only see the bikes from your hub): </label><br />
                    {/* {JSON.stringify(user)} */}
                    <select name="filterBike" id="filterBike" value={filter} onChange={changeFilter}>
                        <option value="na">-- Choose --</option>
                        <option value="Waiting for Release">Waiting for Release</option>
                        <option value="Available">Available</option>
                        <option value="Donated">Delivered</option>
                        <option value="Reserved">Reserved</option>
                        <option value="Donated">Delivered</option>
                        <option value="Deleted">Deleted</option>
                        <option value="For Parts Only">For Parts Only</option>
                        <option value="Needs Repair">Needs Repair</option>
                        <option value="Waiting for Approval">Waiting for Approval</option>

                    </select>
                </form></div>
            {filter !== "na" ?
                <>
                    <h3>{filter === "Donated" ? "Delivered" : filter} Bikes</h3>
                    <div className="bikeCatalog wrapper">
                        
                        {bikes ?
                            bikes.filter(b => b.status === filter && (b.location === user.location || user.type === "superAdmin")).length > 0 ?
                                bikes.filter(b => b.status === filter && (b.location === user.location || user.type === "superAdmin")).map(bike => {
                                    let uniqueKey = "bike" + bike.id;
                                    return (<Bike user={user} setUser={setUser} key={uniqueKey} bike={bike} bikes={bikes} setBikes={setBikes} locationsDateTimes={locationsDateTimes} selectedBike={selectedBike} setSelectedBike={setSelectedBike} />);
                                }) :
                                <div className="centeredP"><p>No {filter} bikes in the system.</p></div>
                            : <h3>Loading bikes...</h3>
                        }
                    </div>
                </>
                : null
            }




        </>


    )

}
export default ViewBikesAdmin;