import TermsAndConditions from "./TermsAndConditions";
import bikeHub2 from './images/logos/bikeHub2.png';
import bikeHub3 from './images/logos/bikeHub3.png';
import gateway from './images/logos/gateway.jpg';
import agata1 from './images/logos/agata1.png';
import agata2 from './images/logos/agata2.png';
import React from 'react';
import cover1 from './images/covers/cover1.jpg';
import cover2 from './images/covers/cover2.jpg';
import cover3 from './images/covers/cover3.jpg';
import cover4 from './images/covers/cover4.jpg';
import cover5 from './images/covers/cover5.jpg';
import cover6 from './images/covers/cover6.jpg';
import cover7 from './images/covers/cover7.jpg';
import cover8 from './images/covers/cover8.jpg';
import cover9 from './images/covers/cover9.jpg';
import cover10 from './images/covers/cover10.jpg';
import cover11 from './images/covers/cover11.jpg';
import './MainPage.css';
import r1 from './images/results/results1.png';
import r2 from './images/results/results2.png';
import r3 from './images/results/results3.png';
import r4 from './images/results/results4.png';
import rt from './images/results/resultsTitle.png';


import { Slide } from 'react-slideshow-image';
import { useState, useEffect } from 'react';

import 'react-slideshow-image/dist/styles.css'


const slideImages = [
    {
        url: cover1,
        caption: "CycleTO event"
    },
    {
        url: cover3,
        caption: "Mother and daughter"
    },
    {
        url: cover4,
        caption: "3 generations"
    },
    {
        url: cover5,
        caption: "Mother and 3 kids"
    },
    {
        url: cover10,
        caption: "elder woman"
    },
    {
        url: cover7,
        caption: "Family of 4"
    },
    {
        url: cover8,
        caption: "Red bike and shirt"
    },
    {
        url: cover6,
        caption: "Mother and son and daughter at the back"
    },
    {
        url: cover2,
        caption: "blue bike yellow post"
    },
    {
        url: cover9,
        caption: "2 mothers and 2 sons"
    },
    {
        url: cover11,
        caption: "3 adults and 4 children"
    },




];
const centeringText = {
    textAlign: "center",
};

const properties = {
    duration: 1500,
    transitionDuration: 1500,
    infinite: true,
    indicators: true,
    arrows: false
};


const MainPage = ({ isPaused }) => {
    const [imageSrc, setImageSrc] = useState(r1);
    let imageIndex = 0;
    let images = [r1, r2, r3, r4]
    useEffect(() => {
        let intervalId = setInterval(() => {
            //console.log(imageIndex)

            let ilocal = imageIndex + 1;
            //console.log('before: '+ilocal)
            if (ilocal >= images.length)
                ilocal = 0;

            //console.log('after: '+ilocal)

            imageIndex = ilocal;
            setImageSrc(images[imageIndex])

        }, 3000)
        return () => clearInterval(intervalId);

    }, [])
    useEffect(() => {
        setImageSrc(images[imageIndex])
    }, [])
    const [bikesDonated, setBikesDonated] = useState(0);
    useEffect(() => {
        fetch('/getAllBikesNoPics', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({}),
        })
            .then(response => response.json())
            .then(data => {
                let local = data;
                //console.log(local);
                let value = local.filter(b => {
                    //console.log(b.status)
                    return (b.status == "Donated")
                });
                //console.log(value.length);
                setBikesDonated(value.length);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, [])

    let today = new Date();
    let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', "Oct", "Nov", "Dec"];
    today = months[today.getMonth()]+' '+today.getFullYear();
    const [usersInTheSytem, setUsersInTheSystem] = useState(0);
    useEffect(() => {
        fetch('/getNumberOfUsers')
            .then(response => response.json())
            .then(data => {
                //console.log(data);
                setUsersInTheSystem(data[0].count);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, []);

    let showResults2023 = false;
    return (
        <>
            <div className="banner">
                <div className="mainTextStyle">
                    <h1 className="mainTitle">
                        Welcome to BikeMatchWCN
                    </h1>

                    <div className="headerText">
                        <div className="leftText">
                            <div className="leftText2">
                            <p >Need a bike to get around?</p>
                            <p> Have a bike you no longer need?</p>
                            <p>We match bikes that people no longer need with families all over Toronto.</p></div>
                            <div className="starResults">  <strong><span className="hugeText">{212+bikesDonated}</span></strong> BIKES DELIVERED</div>
                        </div>
                        
                    {showResults2023?
                        <div className="imageRight">
                        <img id="titleMobile" src={rt} />
                        <img id="resultsImages" src={imageSrc} />
                        </div>:null}
                    </div>
                    
                </div>
                <header>
                    <div className="slide-container">
                        <Slide {...properties}>
                            {slideImages.map((slideImage, index) => (
                                <div key={index}>
                                    <div className="divStyle" style={{ 'backgroundImage': `url(${slideImage.url})` }}>
                                    </div>
                                </div>
                            ))}
                        </Slide>
                    </div>


                </header>
            </div>

            <div className="mainPageContent clearfix wrapper" style={centeringText}>
                {isPaused ?
                    <>  
                    <h3>The BikeMatch program is paused</h3>
                    <p>Please check back on March 4, 2023 to experience a new and improved BikeMatchWCN!</p>
                    <p>Already registered with us? Your information and priority for a bike will not change.</p>
                    </> : <>
                        <h3>Sign up to get a bike</h3>
                        <p>To create a new user, click on the <b>Sign Up</b> button on the top Menu.</p>
                        <p>That will redirect you to a page to fill in your information, including bike size.</p>
                        <p>After you Sign up, you will be able to see the available bikes in your size.</p>
                        <p>Choose a bike and a date and time to reserve the bike.</p>
                        <p>Picked up your reserved bike at the day and time at the Bike Hub location.</p></>}

                      
                        
                
                
                <h3>Donating a bike?</h3>
                <p>We accept all types of bikes, repair them if necessary, and offer them to people in need of a bike.</p>
                <p> Simply take your bike to one our partner bike hub locations shown below.</p>
                <p> Be sure to call first and arrange a drop-off time and date. </p>

                
                
                
                <div className="locations">

                    <ol>
                        <li><div className="halfSplit">
                            <div className="imageLocal">
                                <img src={gateway} alt="Gateway Bicycle Hub" />
                            </div>
                            <div className="textLocal">
                                <b>Gateway Bicycle Hub</b>
                                <p>10 Gateway Blvd. Suite 100, M3C 3A1, North York, ON</p>
                                <p>(416) 551-2011 - <a className="simpleLink" href="mailto:info@gatewaybikehub.org" target="new">info@gatewaybikehub.org</a></p>
                                <p><b>Website:</b> <a className="simpleLink" href="http://www.gatewaybikehub.org" target="new">www.gatewaybikehub.org</a></p>
                                <p><b>Hours:</b></p>
                                <ul><li>Mondays to Fridays: 10 AM - 4 PM</li></ul>
                            </div>
                        </div>
                        </li>
                        <li><div className="halfSplit">
                            <div className="imageLocal">
                                <img src={bikeHub2} alt="AccessPOint on Danforth" />
                            </div>
                            <div className="textLocal">
                                <b>Scarborough Cycles</b>
                                <p><b>AccessPoint on Danforth</b>: 3079 Danforth Ave. Toronto, ON, M1L 1A8.</p>
                                <p><b>Lawrence-Orton Bicycle Repair Hub</b>: 3939 Lawrence Ave E, Toronto, ON, M1G 1R9.<br />Building 1, Enter via north side doors. </p>
                                <p>(416) 699-7920 ext. 4231 - <a className="simpleLink" href="mailto:scarboroughcycles@accessalliance.ca" target="new">scarboroughcycles@accessalliance.ca</a></p>
                                <p><b>Websites:</b> <a className="simpleLink" href="http://www.accessalliance.ca" target="new">accessalliance.ca</a> / <a className="simpleLink" href="http://www.scarboroughcycles.ca" target="new">scarboroughcycles.ca</a></p>
                                <p><b>Hours:</b></p>
                                <ul>
                                    <li>Mondays, Tuesdays, Wednesdays and Fridays: 9:30 AM - 4:30 PM</li>
                                    <li>Thursdays: 12:30 PM - 7:30 PM</li>
                                </ul>
                            </div>
                        </div>
                        </li>
                        {/* <li><div className="halfSplit">
                            <div className="imageLocal">
                                <img src={bikeHub3} alt="Bikes for Climate Hub" />
                            </div>
                            <div className="textLocal">
                                <b>Bikes for Climate Hub</b>
                                <p>2667 Kipling Ave, M9V 4N9, Etobicoke, ON</p>
                                <p>(647) 679-4289 - <a className="simpleLink" href="mailto:bikesforclimatehub@gmail.com" target="new">bikesforclimatehub@gmail.com</a></p>
                                <p><b>Website:</b> <a className="simpleLink" href="http://www.capnetwork.ca/bikes-for-climate-hub/" target="new">www.capnetwork.ca/bikes-for-climate-hub/</a></p>
                                <p><b>Hours:</b></p>
                                <ul><li>Saturdays: 1 PM - 6 PM</li></ul>
                            </div>
                        </div>
                        </li> */}
                        <li><div className="halfSplit">
                            <div className="imageLocal">
                                <img src={agata1} alt="Culture Link Bike Hub" /><br />
                                <img src={agata2} alt="Culture Link Logo" />
                            </div>
                            <div className="textLocal">
                                <b>CultureLink</b>
                                <p>15 Tobermory Drive, North York, ON, M3N 2R5</p>
                                <p>(416) 420-3664 - <a className="simpleLink" href="mailto:bikehub@culturelink.ca" target="new">bikehub@culturelink.ca</a></p>
                                <p><b>Website:</b> <a className="simpleLink" href="https://https://www.culturelink.ca/bike-hub/" target="new">www.culturelink.ca/bike-hub</a></p>
                                <p><b>Hours:</b></p>
                                    <ul><li>Tuesdays: 3PM - 7PM</li><li>Thursdays: 3PM - 7PM </li><li>Saturday: 10AM-3PM <small>(Starting January 13th, we're open every other Saturday)</small></li></ul>.
                            </div>
                        </div>
                        </li>
                    </ol>
                </div>
                <p>Once your bike is deemed ready-to-ride, our volunteers will take a photo of the bike  and register it in the program.
                </p>

                <div className="wrapper">
                    <h3>About BikeMatchWCN</h3>
                    <p>Modeled after U.S. programs created to get bicycles into the hands of essential workers, BikeMatchWCN was developed by the <a className="simpleLink" href="https://www.womenscyclingnetwork.ca/">Women's Cycling Network</a> to provide bikes to women as “wheels of empowerment”. Since launching in 2020, the program has exceeded all expectations thanks to the generosity of people in Toronto who donated bikes they didn’t need. </p>
                    <p>Based on our success, we have expanded the program to include anyone seeking a bike to ride, including children. This will allow many more women to enjoy cycling with their family and friends while providing them with more opportunities to ride a bike.
                    </p>

                    <h4>About the Women's Cycling Network (WCN)</h4>
                    <p>The Women's Cycling Network works to promote cycling as wheels of empowerment for women of all backgrounds, and especially for those whose culture prohibits them from riding a bike.</p>

                    <p>We bring together women who are new to biking and experienced cyclists, serving both newcomers to Canada as well as long-term residents, with the goal of helping those who identify as female feel safer getting around by bike.
                    </p>

                    <p>By encouraging all women to ride a bike, we hope to change the conception of what it means to be a cyclist and normalize cycling within our community.</p>

                    <p>For more information, visit the <a className="simpleLink" href="https://www.womenscyclingnetwork.ca/">Women's Cycling Network website</a>.</p>
                </div>
                {!isPaused ? <TermsAndConditions /> : null}
            </div>

        </>

    )
}
export default MainPage;