import './Bike.css'
import { useEffect, useState } from 'react';


const ChosenBike = ({ user, setUser, bikes, pickupSlots, sendEmail, logout }) => {

    let [locations, setLocations] = useState([]);
    let [bikeImage, setBikeImage] = useState();
    
    useEffect(() => {
        loadLocations();
    }, []);
    function loadLocations() {

        fetch("/getAllLocations")
            .then(res => {
                let response = res;
                //console.log(response);
                return (response.json());
            })
            .then(response => {
                let final = response;
                //console.log('Loading locations: ',final);
                setLocations(final);

            })

            .catch(
                e => {
                    //console.log("Error:" + e)
                    alert("There was an error connecting to our server to load the locations. Could you logout, login and try again?")
                    logout();
                }

            )
    }

    let [fullLocationDetails, setFullLocationDetails] = useState([])
    useEffect(() => {
        //console.log(bike)
        let thisLocation = locations.filter(l => l.id === bike.location)[0]
        //console.log('Set details: ',thisLocation)
        setFullLocationDetails(thisLocation)
    }, [locations]);

    //console.log("BIKES: ", bikes)
    let bike = bikes.filter(b => b.id === user.bike_id)[0]
    let [local, setLocal] = useState();
    useEffect(() => {
        //console.log(bike)
        if (bike){
        fetch('/getBikeWithPic', {
            'method': 'POST',
            'headers': {
              'Content-Type': 'application/json'
            },
            'body': JSON.stringify(bike)
          }).then(res => {
            let response = res;
      
            return (response.json());
          }).then(res => {
            let response = res
            //console.log('INSIDE RESPONSE')
            //console.log(response[0])
            setBikeImage(response[0].picture)
           
          })
        }
    }, []);

    let [date, setDate] = useState();
    let [showCancel, setShowCancel] = useState();

    useEffect(() => {
        if (pickupSlots) {
            let l = pickupSlots.filter(loc => loc.id === bike.location)[0];
            let finalL = { ...l }
            setLocal(finalL);
        }
    }, []);

    useEffect(() => {
        if (pickupSlots) {
            let l = pickupSlots.filter(loc => loc.id === bike.location)[0];
            let date = l['pickup_slots'].filter(slot => slot.id === user.pickup_slot)[0].start_time
            setDate(date);
        }
    }, []);


    useEffect(() => {
        if (pickupSlots) {
            let l = pickupSlots.filter(loc => loc.id === bike.location)[0];
            let finalL = { ...l }
            setLocal(finalL);
        }
    }, [user, pickupSlots]);
    useEffect(() => {
        if (pickupSlots) {
            let l = pickupSlots.filter(loc => loc.id === bike.location)[0];
            let date = l['pickup_slots'].filter(slot => slot.id === user.pickup_slot)[0].start_time
            setDate(date);
        }
    }, [user, pickupSlots]);


    useEffect(() => {
        if (date){
        let iosDate = date.replace(/\-/g, '/');
        let pickupDate = new Date(iosDate);
        let pickupDateMs = pickupDate.getTime();
        let today = new Date();
        let todayMs = today.getTime();
        let daysBefore = 1;
        let dayInMs = daysBefore * 24 * 60 * 60 * 1000;
        let lastChanceMs = pickupDateMs - dayInMs;
        //alert('PickupDate: '+pickupDateMs+' Today: '+todayMs+" Last chance: "+lastChanceMs);
        if (todayMs < lastChanceMs) {
            setShowCancel(true)
        } else {
            setShowCancel(false)
        }
    }
    }, [date]);

    function handleCancel() {
        let confirmCancel = window.confirm("Are you sure you want to cancel? You will lose your spot and the priority for this bike. This process cannot be reversed.");
        if (confirmCancel) {
            fetch('/cancelBikeReservation', {
                'method': 'POST',
                'headers': {
                    'Content-Type': 'application/json'
                },
                'body': JSON.stringify(user)
            }).then(response => {
                if (response.status === 200) {
                    //alert('User updated!');
                    let recipient = user.email;
                    let subject = "Your bike reservation has been cancelled";
                    let text = 'Hi '+user.first_name+' '+user.last_name+'.'+"Thank you for letting us know that "+user.login_name+" no longer wants this bike. We have returned it to inventory to be available for others in need of a bike.";
                    let html = 'Hi '+user.first_name+' '+user.last_name+'.'+"<p>Thank you for letting us know that "+user.login_name+" no longer wants this bike.<p/><p> We have returned it to inventory to be available for others in need of a bike.</p>";
                    let replyTo = "bikematchwcn@gmail.com";
                    sendEmail(recipient, subject, text, html, replyTo);

                    let bikeHubEmail = fullLocationDetails.email;
                    let textToHub =  'User '+user.first_name+' '+user.last_name+" ("+recipient+") CANCELLED the RESERVATION of the bike " + bike.tag+". Reply to this email if you need to talk to the user.";
                    //console.log("sending reservation cancelled to "+bikeHubEmail+": "+textToHub);
                    sendEmail(bikeHubEmail, "RESERVATION CANCELLED", textToHub, textToHub, recipient);

                    response.json().then(refUser => {
                        // console.log("Refreshed user: ", refUser);
                        setUser(refUser);
                        localStorage.setItem('user', JSON.stringify(refUser));
                    });

                } else {
                    // read the response body as plain text
                    response.text().then(text => {
                        //  console.log('Updated user: ' + text);

                    });
                }
            }).catch(error => {
                //console.log(error);
                alert("There was an error cancelling the reservation. Please reload the page and try again. Error: "+error)
            });
            return false;
        }
        else {
            //console.log('Cancel cancelled.');
        }

    }

    return (<div className="bikeBox chosenBike">
        <div className="bikeTag"> {bike.tag}</div>
        <div className="bikeImage"> {bikeImage?<img src={`data:image/jpeg;base64,${bikeImage}`} alt="bike" />:null}</div>
        <div className="bikeDescription"> Description: {bike.description}</div>

        {fullLocationDetails ? <>
            <div className="bikeLocation"> <b>Location:</b> {fullLocationDetails.name}</div>
            <div className="bikeLocation"> <b>Web:</b> <a href={'https://' + fullLocationDetails.website} target="_blank" rel="noreferrer">{fullLocationDetails.website}</a>
                {fullLocationDetails.alternative_website ?
                    <> or  <a href={'http://' + fullLocationDetails.alternative_website} target="new"> {fullLocationDetails.alternative_website}</a>
                    </>
                    : null}
            </div>
            <div className="bikeLocation"> <b>Address:</b> {fullLocationDetails.address}, {fullLocationDetails.postal_code}, {fullLocationDetails.city}, {fullLocationDetails.province}</div>
            <div className="bikeLocation"> <b>Email:</b> <a href={`mailto:` + fullLocationDetails.email}>{fullLocationDetails.email}</a></div>
            <div className="bikeLocation"> <b>Contact Info:</b> {fullLocationDetails.phone_number} {fullLocationDetails.contact ? ", " + fullLocationDetails.contact : null}</div>
            <div className="bikeLocation"> <b>Pickup:</b> {date}</div>
            
        </> : <p>Loading...</p>

        }

        <div className="cancelButton" >

            {bike.status === "Donated" ? <div className="cannotCancel">CONGRATULATIONS!<br />
                You got your bike!</div>
                : showCancel ?
                    <button type="button" id="cancelButton" onClick={handleCancel}>Cancel</button>
                    : <div className="cannotCancel">Your pick-up date is less than 24 hours away and the option to cancel is not possible. For assistance, please contact the Bike Hub where you were to pick up your bike.</div>
            }


            
        </div>
    </div>);
}
export default ChosenBike;