import { useState, useEffect } from 'react';

// IMAGES:


const AddPickupDate = ({ user, logout }) => {

  let [locations, setLocations] = useState([]);
  useEffect(() => {
    loadLocations();
  }, []);
  function loadLocations() {
    fetch("/getAllLocations")
      .then(res => {
        let response = res;
        //console.log(response);
        return (response.json());
      })
      .then(response => {
        let final = response;
        //console.log(final);
        setLocations(final);
      })

      .catch(
        e => {
          //console.log("Error:" + e)
          alert("There was an error connecting to our server to load the locations. Could you logout, login and try again?")
          logout()
        }

      )
  }


  useEffect(() => {
    loadLocationOptions();
  }, [locations]);
  function loadLocationOptions() {
    let locs = document.getElementById("location");
    locs.innerHTML = '';
    let option = document.createElement("option");
    option.text = "-- Choose --";
    option.value = "choose";
    locs.add(option);
    locs.selectedIndex = "0";
    if (locations.length > 0) {
      locations.forEach(l => {
        let option = document.createElement("option");
        option.text = l.id + " - " + l.name;
        option.value = l.id;
        locs.add(option);
      })
    }
  }


  let [users, setUsers] = useState();
  useEffect(() => {
    loadUsers();
  }, []);

  function loadUsers() {
    let data = {};
    fetch("/getAllUsers", {
      method: "POST",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" }
    })
      .then(res => {
        let response = res;
        //console.log(response);
        return (response.json());
      })
      .then(response => {
        let final = response;
        // console.log(final);
        setUsers(final);

      })

      .catch(
        e => {
          //console.log("Error:" + e)
          alert("There was an error connecting to our server to load the users. Could you logout, login and try again?")
          logout()
        }
      )


  }

  let [allPickupSpots, setAllPickupSpots] = useState();
  useEffect(() => {
    loadPickupSpots();
  }, []);

  function loadPickupSpots() {
    fetch("/getAllPickupSlots")
      .then(res => {
        let response = res;
        //console.log(response)
        return (response.json())
      })
      .then(response => {
        let final = response;
        //console.log(final);
        setAllPickupSpots(final);
        
      })
      .catch(
        e => {
          //console.log("Error:" + e)
          alert("There was an error connecting to our server to load the pickup spots. Could you logout, login and try again?")
          logout();
        }
      )

  }


  function addPickupDate() {
    //console.log('add a pickupdate');
    let locs = parseInt(document.getElementById("location").value);
    let locationName = locations.filter(l => l.id == locs)[0].name;
    //console.log(locs);
    if (locs) {
      let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      let d = document.getElementById('newPickupDate').value.replace(/\-/g, '/');
      let s = document.getElementById('newPickupDateStart').value;
      let e = document.getElementById('newPickupDateEnd').value;
      let i = document.getElementById('interval').value;
      //console.log(d, s, e)
      if (d && s && e) {
        let pickupSpots = [];
        let minutes = parseInt(i);
        let init = parseInt(s.split(":")[0]) * 60 + parseInt(s.split(":")[1]);
        //console.log(init)
        let final = parseInt(e.split(":")[0]) * 60 + parseInt(e.split(":")[1]);
        //console.log(final)

        for (let t = init; t < final; t += minutes) {
          //console.log(t);
          let addedDate = {};

          let m = t % 60;
          if (m < 10 && m != 0) m = "0" + m;

          addedDate.start_time = d + " " + Math.floor(t / 60) + ":" + m;
          if (t % 60 == 0) addedDate.start_time += "0"

          let endTime = t + minutes;
          let m2 = endTime % 60;
          if (m2 < 10 && m != 0) m2 = "0" + m2;
          addedDate.end_time = d + " " + Math.floor(endTime / 60) + ":" + m2;
          if (endTime % 60 == 0) addedDate.end_time += "0"
          addedDate.location_id = locs;
          //console.log(addedDate);
          pickupSpots.push(addedDate);
          //console.log(pickupSpots);
        }
        let text = '';
        pickupSpots.forEach(p => text += '\n' + p.start_time);

        if (window.confirm('ATTENTION! This action CANNOT BE REVERSED! Are you sure you want to add this ' + pickupSpots.length + ' pickup spots to the database for location ' + locationName + '?' + text)) {
          fetch('/addPickupSpots', {
            'method': 'POST',
            'headers': {
              'Content-Type': 'application/json'
            },
            'body': JSON.stringify(pickupSpots)
          }).then(response => {
            if (response.status === 200) {
              alert('SPOTS ADDED');
              //document.getElementById('location').value = 'choose';
              document.getElementById('newPickupDate').value = 'yyyy-mm-dd';
              document.getElementById('newPickupDateStart').value = '10:00';
              document.getElementById('newPickupDateEnd').value = '13:00';
              loadPickupSpots();
            } else {
              // read the response body as plain text
              response.text().then(text => {
                //alert('Error adding spots.'+text);
                //console.log(text);
                document.getElementById('errorBox').innerHTML = text;
              });
            }
          }).catch(error => {
            alert(error);
            //console.log(error);
          });
          return false;
        }

      } else {
        alert("Choose a valid date, start time and end time.")
      }
    } else {
      alert("Choose a valid location.")
    }

  }


  let [location, setLocation] = useState();

  let [filteredPickupSpots, setFilteredPickupSpots] = useState();


  function filterSpots(event) {
    let loc = event.target.value;
    let hub = locations.filter(l => l.id == loc)[0]
    //console.log(hub)
    setLocation(loc);
    setNewHub(hub);
  }
  useEffect(() => {
    if (user.type!=="superAdmin"){
      let loc = user.location;
      let hub = locations.filter(l => l.id == loc)[0]
     setLocation(user.location)
     setNewHub(hub);
    }
  }, [locations, allPickupSpots])

  


  useEffect(() => {
    if (location && allPickupSpots) {
      let f = allPickupSpots.filter(p => {
        //console.log(p)
        let now = new Date();
        let date = new Date(p.start_time.split(" ")[0].replace("/", "-"));
        //console.log(now, date)
        if (date.getTime() < now.getTime())
          return false
        return (p.location_id == location && p.status != "Deleted")
        //return (p.location_id == location)
      })
      setFilteredPickupSpots(f)
      //console.log(filteredPickupSpots)
    }
  }, [location, allPickupSpots]);



  let today = new Date();

  function deleteSpot(event) {
    let spot = {};
    spot.id = event.target.id;
    //console.log('Spot.id = ' + spot.id);
    //console.log(allPickupSpots);
    let chosenSpot = allPickupSpots.filter(s => s.id == spot.id)[0];
    //console.log("Chose spot start_time:" + chosenSpot.start_time)
    //console.log(location)
    let chosenLocationName = locations.filter(l => l.id == location)[0].name;
    let sure = window.confirm('Are you sure you want to delete this spot at ' + chosenLocationName + " on " + chosenSpot.start_time + "?");
    if (sure) {
      fetch("/deletePickupSlot", {
        method: "POST",
        body: JSON.stringify(spot),
        headers: { "Content-Type": "application/json" }
      })
        .then(res => res.json())
        .then(response => {
          //console.log(response);
          let final = response;
          setAllPickupSpots(final);
        });
    }
  }

  let [newHub, setNewHub] = useState({});

  function updateForm(event) {
    let value = event.target.id;
    let local = { ...newHub }
    local[value] = event.target.value;
    setNewHub(local);
  }

  function reloadPage() {
    loadLocations();
  }
  useEffect(() => {
    let local = {}
    setNewHub(local)
  }, [locations]);


  function addEvent() {
    let local = { ...newHub }
    local.city = "Toronto";
    local.province = "ON";
    if (local.name && local.email) {
      if (window.confirm('ATTENTION! This action CANNOT BE REVERSED! Are you sure you want to add ' + local.name + ' Location to the database?')) {
        fetch('/addLocation', {
          'method': 'POST',
          'headers': {
            'Content-Type': 'application/json'
          },
          'body': JSON.stringify(local)
        }).then(response => {
          if (response.status === 200) {
            alert('Location ADDED');
            reloadPage();

          } else {
            // read the response body as plain text
            response.text().then(text => {
              //alert('Error adding spots.'+text);
              //console.log(text);
              document.getElementById('errorBox').innerHTML = text;
            });
          }
        }).catch(error => {
          alert(error);
          //console.log(error);
        });
        return false;
      }
    }
    else {
      alert('You must choose all the values for the new hub.')
    }
  }

  return (
    <>
      <section id="content" className="wrapper">
        <div className="contentCard wrapper">
          {user.type === "superAdmin" ?
            <>
              <label htmlFor="location">Choose a Location:</label><br />
              <select id="location" name="location" type="select" width="100%" onChange={filterSpots} value={user.location}>
                <option value="choose">-- Choose an option --</option>
              </select>
            </>
            : <>
              <label htmlFor="location">Location <small>(you can only see your location)</small>:</label><br />
              <select disabled id="location" name="location" type="select" width="100%" value={user.location}>
                <option value="choose">-- Choose an option --</option>
              </select>
            </>}
        </div>

        <h3>Add Pickup Date</h3>
        <div className="contentCard wrapper">
          <form>

            <p>Choose the date, the start time, the end time and the spots length (interval). The system will automatically add spots from the start time increased by the interval until the end time. You will see a confirmation window with all the spots before the final confirmation.</p>
            <label htmlFor="newPickupDate">New Pickup Date</label>
            <input type="date" id="newPickupDate" name="newPickupDate" defaultValue={today} /><br />
            <label htmlFor="newPickupDateStart">New Pickup Date Start Time</label>
            <input type="time" id="newPickupDateStart" name="newPickupDateStart" defaultValue="10:00" /><br />
            <label htmlFor="newPickupDateEnd">New Pickup Date End Time</label>
            <input type="time" id="newPickupDateEnd" name="newPickupDateEnd" defaultValue="13:00" /><br />
            <label htmlFor="interval">Interval between spots</label>
            <select id="interval" name="interval" type="select" width="100%" defaultValue="20">
              <option value="10">10 minutes (6 per hour)</option>
              <option value="15">15 minutes (4 per hour)</option>
              <option value="20">20 minutes (3 per hour)</option>
              <option value="30">30 minutes (2 per hour)</option>

            </select>
            <br />
            <div className="wrapper centering">
              <p>
                <label id="errorBox" name="erroBox" className="errorBox"></label>
              </p>
              <button type="button" id="addNewPickupDate" onClick={addPickupDate} className="otherButton">
                Add New Pickup Date
              </button>
            </div>

          </form>
        </div>




        <div className="centering">
          <h3>View future pickup dates</h3>

          {filteredPickupSpots ?
            filteredPickupSpots.map(p => {
              let place = locations.filter(l => l.id == p.location_id)[0].name;

              //console.log(place)
              if (p.status == "Available") {
                return <p className="textAvailable" key={p.id + 'pickup'}>{p.start_time + " - " + place + " = " + p.status}
                  <button type="button" className="deleteSpotButton" id={p.id} onClick={deleteSpot}>Delete</button></p>
              }
              else {
                if (p.status == "Reserved") {
                  return <p className="textReserved" key={p.id + 'pickup'}>{p.start_time + " - " + place + " = " + p.status}</p>
                }
                else {
                  return <p className="textDeleted" key={p.id + 'pickup'}>{p.start_time + " - " + place + " = " + p.status}</p>
                }
              }
            }
            ) : <p>No Spots. Select a Location.</p>
          }
        </div>

        {/* <h3>Add Event</h3> 
        <div className="contentCard wrapper">

          <form>
            <p>Create a new Event, such as Bike Giveaways. These events will be similar to creating a new hub. You can add bikes to it and pickup date(s). The first step is to choose a Location to create a pre-filled form. After the event is created, you can select it as a Location and add pickup dates.</p>
            <label htmlFor="name">Event Name:*</label>
            <input type="text" id="name" onChange={updateForm} value={newHub.name ? newHub.name : ""}></input>
            <label htmlFor="address">Address:*</label>
            <input type="text" id="address" onChange={updateForm} value={newHub.address ? newHub.address : ""}></input>
            <label htmlFor="postal_code">Postal Code:*</label>
            <input type="text" id="postal_code" onChange={updateForm} value={newHub.postal_code ? newHub.postal_code : ""}></input>
            <label htmlFor="phone_number">Phone Number:*</label>
            <input type="text" id="phone_number" onChange={updateForm} value={newHub.phone_number ? newHub.phone_number : ""}></input>
            <label htmlFor="website">Website:*</label>
            <input type="text" id="website" onChange={updateForm} value={newHub.website ? newHub.website : ""}></input>
            <label htmlFor="alternative_website">Alternative Website:</label>
            <input type="text" id="alternative_website" onChange={updateForm} value={newHub.alternative_website ? newHub.alternative_website : ""}></input>
            <label htmlFor="contact">Contact:*</label>
            <input type="text" id="contact" onChange={updateForm} value={newHub.contact ? newHub.contact : ""}></input>
            <label htmlFor="email">Email:*</label>
            <input type="text" id="email" onChange={updateForm} value={newHub.email ? newHub.email : ""}></input>
          </form>
          <div className="wrapper centering">
            <button type="button" id="addEvent" onClick={addEvent} className="otherButton">
              Add Event
            </button>
          </div>
        </div> */}
        <br/>
        <br/>


      </section>
    </>
  )
}
export default AddPickupDate;