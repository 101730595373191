import { useState, useEffect } from 'react';

// IMAGES:

const AddAdminUser = ({ user, logout }) => {


  let [locations, setLocations] = useState([]);
  useEffect(() => {
    loadLocations();
  }, []);
  function loadLocations() {

    fetch("/getAllLocations")
      .then(res => {
        let response = res;
        //console.log(response);
        return (response.json());
      })
      .then(response => {
        let final = response;
        //console.log('Loading locations: ',final);
        setLocations(final);

      })

      .catch(
        e => {
          //console.log("Error:" + e)
          alert("There was an error connecting to our server to load the locations. Could you logout, login and try again?")
          logout()
        }

      )
  }
  useEffect(() => {
    loadLocationOptions();
  }, [locations]);
  function loadLocationOptions() {
    let locs = document.getElementById("location");
    locs.innerHTML = '';
    let option = document.createElement("option");
    option.text = "-- Choose --";
    locs.add(option);
    locs.selectedIndex = "0";
    if (locations.length > 0) {
      locations.forEach(l => {
        let option = document.createElement("option");
        option.text = l.id + " - " + l.name;
        option.value = l.id;
        locs.add(option);
      })
    }
  }

  function addAdminUser() {

    if (window.confirm('ATTENTION! This action CANNOT BE REVERSED! Are you sure you want to add ' + formData.first_name + " " + formData.last_name + ' (' + formData.login_name + ')' + ' as an admin user?')) {
      fetch('/addAdminUser', {
        'method': 'POST',
        'headers': {
          'Content-Type': 'application/json'
        },
        'body': JSON.stringify(formData)
      }).then(response => {
        if (response.status === 200) {
          alert('Admin User ADDED');
          document.getElementById('login_name').value = '';
          document.getElementById('first_name').value = '';
          document.getElementById('last_name').value = '';
          document.getElementById('email').value = '';
          document.getElementById('password').value = '';
          document.getElementById('errorBox').innerHTML = '';
          document.getElementById('location').innerHTML = '';
          setFormData([]);
        } else {
          response.text().then(text => {
            document.getElementById('errorBox').innerHTML = text;
          });
        }
      }).catch(error => {
        alert(error);
      });
      return false;
    }
  }

  let today = new Date();
  const [formData, setFormData] = useState({});
  function handleChangeInput(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    let localData = { ...formData };
    localData[name] = value;
    if (user.type!="superAdmin") localData.location = user.location;

    //Adds the log:
    let today = new Date();
    localData.log = "Admin user created by " + user.login_name + " on [" + today + "].";
    //console.log(localData);
    setFormData(localData);
  }
  return (
    <>
      <section id="content" className="wrapper">
        <h3>Add new Admin User</h3>
        <div className="contentCard wrapper">
          <form>
            <label htmlFor="login_name">Login Name (can be the same as email)*</label>
            <input type="text" id="login_name" name="login_name" onChange={handleChangeInput} required /><br />
            <label htmlFor="email">Email</label>
            <input type="email" id="email" name="email" onChange={handleChangeInput} required /><br />
            <label htmlFor="first_name">First Name</label>
            <input type="text" id="first_name" name="first_name" onChange={handleChangeInput} required /><br />
            <label htmlFor="last_name">Last Name</label>
            <input type="text" id="last_name" name="last_name" onChange={handleChangeInput} required /><br />
            <label htmlFor="password">Password</label>
            <input type="password" id="password" name="password" onChange={handleChangeInput} required /><br />
            {user.type==="superAdmin"?
            <>
            <label htmlFor="location">Location <small>(superAdmin can choose)</small></label>
            <select onChange={handleChangeInput}  id="location" name="location" type="select" width="100%" required value={formData? formData.location:""}>
              <option>-- Choose an option --</option>
            </select>
            </>
            : <>
            <label htmlFor="location">Location <small>(you can only create Admins in your Hub)</small></label>
            <select disabled onChange={handleChangeInput}  className="disabledSelect"  id="location" name="location" type="select" width="100%" required value={user.location}>
              <option>-- Choose an option --</option>
            </select>
            </>
            }
            <div className="wrapper centering">
              <p>
                <label id="errorBox" name="erroBox" className="errorBox"></label>
              </p>
              <button type="button" id="addAdmin" onClick={addAdminUser} className="otherButton">
                Add Admin User
              </button>
            </div>

          </form>
        </div>


      </section>
    </>
  )
}
export default AddAdminUser;