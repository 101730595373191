import Row from './Row.js';

const Schedule = ({ location, location_name, date, data, user, loadData, sendEmail }) => {
  let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dez'];

  function print(){
    printData(location, location_name, date);
  }

  function printData(location, location_name, date) {
    let divToPrint = document.getElementById('table');
    let htmlToPrint = '' +
        '<style type="text/css">' +
        'body{'+
        'font-family: Arial, Helvetica, sans-serif;'+
        '}'+
        'table th, table td {' +
        'border:1px solid #000;' +
        'padding:0.5em;' +
        'width: 400px;'+
        '}' +
        '.finalCancelButton, .finalConfirmButton{display:none} '+
        '.actionLine{width: 100px} '+
        '</style>';
    htmlToPrint +='<h1>'+location+' - '+location_name+'-'+date.split("/")[0]+"/"+months[date.split("/")[1]]+'</h1>';
    htmlToPrint += divToPrint.outerHTML;
    let newWin = window.open("");
    newWin.document.write(htmlToPrint);
    newWin.print();
    // newWin.close();
  }

  return (
    <>
    <h1>{location} - {location_name} - {date.split("/")[0]}/{months[date.split("/")[1]]}</h1>
      <table id="table">
      
        <tbody >
          <tr>
          <th>Time Slot</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Phone #</th>
            <th>Bike #</th>
            <th className="statusLine">Status</th>
            <th className="actionLine">Delivered</th>
          </tr>
          {
            data.map(e => {
              // console.log(e);
              const uniqueKey = 'user'+e.login_name;
              return <Row key={uniqueKey} e={e} user={user} loadData={loadData} sendEmail={sendEmail}/>
            }
            )}


        </tbody>
      </table>
      <button type="button" onClick={print} className="otherButton">PRINT</button>
    </>
  )
}
export default Schedule;