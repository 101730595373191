import TermsAndConditions from './TermsAndConditions.js';
import { useState } from 'react';

const AddUser = ({ setUser, sendEmail, setCurrentPage }) => {

    const [formData, setFormData] = useState({});
    let [errorText, setErrorText] = useState('');
    let needFor = ['commute to work/school', "running errands and shopping", "physical health", "mental health", "recreation", "trail and mountain biking", "cycling with family"];

    function addUser() {
        //console.log('Add user!');
        let user = { ...formData };
        if (!user.city) user.city = "Toronto";
        user.province = "ON"; // fixed for now
        user.type = "recipient";
        user.date_included = new Date().toISOString().split("T")[0].replace(/\-/g, '/')
        let today = new Date();
        user.log = "User created on " + today + "."
        // if (!user.gender) user.gender="Other";
        // If birthdate is set, calculate age
        if (user.birthday) {
            let today = new Date();
            let birthDate = new Date(user.birthday);
            let age = today.getFullYear() - birthDate.getFullYear();
            let m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            user.age = age;
        }

        let finalR = '';
        for (let r in user["needBikeFor"])
            if (user["needBikeFor"][r]) {
                finalR += r + "; "
            }
        //console.log('*** needBikeFor: ', finalR)
        user.reason = finalR + user.reason;

        //console.log('Reason: ' + user.reason);

        fetch('/users', {
            'method': 'POST',
            'headers': {
                'Content-Type': 'application/json'
            },
            'body': JSON.stringify(user)
        }).then(response => {
            if (response.status === 200) {
                alert('Thanks for signing up. We will email you when bikes are available in your size.');
                let recipientName = user.login_name;
                let recipientEmail = user.email;

                let subject = "Your registration is confirmed!";
                let text = 'Hi '+user.first_name+' '+user.last_name+'.'+"Thank you for signing "+user.login_name+" up to receive a bike from BikeMatchWCN. Your email " + user.email + " was used to sign up. If you did not do this, please reply to this email right away.";
                let html = '<p>Hi '+user.first_name+' '+user.last_name+'.</p>'+"<p>Thank you for signing "+user.login_name+" up to receive a bike from BikeMatchWCN.</p><p>Your email " + user.email + " was used to sign up.</p><p>If you did not do this, please reply to this email right away.</p>";
                let replyTo = "bikematchwcn@gmail.com";
                sendEmail(recipientEmail, subject, text, html, replyTo);
                localStorage.setItem('user', JSON.stringify(user));
                setCurrentPage('viewBikesPage')
                setUser(user)
            } else {
                // read the response body as plain text
                response.text().then(text => {
                    //console.log('Error adding user: ' + text);
                    setErrorText('Error adding user: ' + text);
                });
            }
        }).catch(error => {
            //console.log(error);
            setErrorText('Error adding user!');
        });
        return false;
    }


    function handleChangeInput(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const id = target.id;
        let localData = { ...formData };

        //console.log(event.target);
        //console.log(value);

        if (name === "postal_code" && value.length >= 3) {
            if (value[0].toUpperCase() !== "M") {
                alert('Sorry. We are only accepting entries from Toronto Postal Codes.');
                event.target.value = "";
            }
        }


        if (name == "needBikeFor") {
            if (localData[name])
                localData[name][id] = value;
            else {
                localData[name] = {}
                localData[name][id] = value;
            }



        } else {
            localData[name] = value;
        }
        //console.log(localData);
        setFormData(localData);
    }

    function handleChangeHeight1(event) {
        let heightCm = event.target.value;
        let heightFt = Math.floor(heightCm / 30.48);
        let heightIn = Math.floor((heightCm - (heightFt * 30.48)) / 2.54);
        let localData = { ...formData };
        localData.height_cm = heightCm;
        localData.height_ft = heightFt;
        localData.height_in = heightIn;
        localData.bike_size = calculateBikeSize(heightCm);
        // console.log(localData);
        setFormData(localData);
    }

    function handleChangeHeight2(event) {
        let heightFt = event.target.value;
        let heightCm = (heightFt * 30.48 + formData.height_in * 2.54).toFixed(0);
        let localData = { ...formData };
        localData.height_ft = heightFt;
        localData.height_cm = heightCm;
        localData.bike_size = calculateBikeSize(heightCm);
        //console.log(localData);
        setFormData(localData);
    }

    function handleChangeHeight3(event) {
        let heightIn = event.target.value;
        let heightCm = (formData.height_ft * 30.48 + heightIn * 2.54).toFixed(0);
        let localData = { ...formData };
        localData.height_in = heightIn;
        localData.height_cm = heightCm;
        localData.bike_size = calculateBikeSize(heightCm);
        // console.log(localData);
        setFormData(localData);
    }


    function calculateBikeSize(heightCm) {
        let h = heightCm / 100; //meters
        //console.log("Height = " + h + "m");
        let limitsF = [0,0,0,0,0,0,4, 5, 5, 5, 6, 6, 6];
        let limitsI = [36, 37, 41, 45, 49, 56, 10, 2, 6, 10, 1, 4, 6];
        let limits = [];
        for (let i = 0; i < limitsF.length; i++) {
            limits[i] = toMeter(limitsF[i], limitsI[i]);
        }

        
        let kidsHeightFt = [
            36,37,41,45,49,56
        ]
        let kidsHeightMetric = kidsHeightFt.map(h => toMeter(0,h))
        //console.log('Kids heights in Metric = ',kidsHeightMetric)

        let bikeSize = [
            "Toddler",
            "Kids12", 
            "Kids14", 
            "Kids16", 
            "Kids20", 
            "Kids24",
            "Kids26",
            "XSmall",
            "Small",
            "Medium",
            "Large",
            "XLarge",
            "XXLarge",
            "NotAvailable (height too big)"
        ];
        let thisBikeSize = 0;
        for (
            thisBikeSize = 0;
            thisBikeSize < bikeSize.length && h >= limits[thisBikeSize];
            thisBikeSize++
        ) {
            //console.log(thisBikeSize);
        }
        return bikeSize[thisBikeSize];

    }

    let [canAddUser, setCanAddUser] = useState(false)
    function enableAddUser(event) {
        handleChangeInput(event);
        setCanAddUser(!canAddUser);
    }

    //to convert feet and inches to meters
    function toMeter(f, i) {
        let value = (f * 30.48 + i * 2.54) / 100;
        //console.log("to meter", value);
        return value;
    }

    return (
        <>
            <section id="content" className="wrapper">
                <h3>Sign Up</h3>
                <div className="contentCard">
                    <form method="POST">
                        <label htmlFor="login_name">Choose your Username* <small>(A unique identifier for you to use to log in the system to see and reserve bikes. There are no restrictions on size or characters, but it needs to be unique.)</small></label><br />
                        <input onChange={handleChangeInput} id="login_name" name="login_name" type="text" placeholder="Unique Login" required />
                        <br />
                        <label htmlFor="first_name">First Name* </label>
                        <input onChange={handleChangeInput} id="first_name" name="first_name" type="text" placeholder="First Name" required />
                        <br />
                        <label htmlFor="last_name">Last Name* </label>
                        <input onChange={handleChangeInput} id="last_name" name="last_name" type="text" placeholder="Last Name" required />
                        <br />
                        <label htmlFor="email">Email* <small>(This is the email to be contacted when a bike is available in your size)</small></label>
                        <input onChange={handleChangeInput} id="email" name="email" type="email" placeholder="Fill your email here" required />
                        <br />
                        <label htmlFor="email">Password* <small>(No requirements)</small></label>
                        <input onChange={handleChangeInput} id="password" name="password" type="password" placeholder="Fill your password here" required />
                        <br />

                        <label htmlFor="date">Age* <small>(Please enter your current age)</small></label>
                        <input onChange={handleChangeInput} type="number" min="1" max="100" name="age" id="age" required /><br />

                        {/* <p>
                          <input onChange={handleChangeInput} type="checkbox" id="gender" name="gender" />
                           <label htmlFor="gender">I identify as female.</label><br />
                           </p>  */}
                        <label htmlFor="gender">How do you identify as?*</label><br />
                        <select onChange={handleChangeInput} id="gender" name="gender" required>
                            <option value="choose" defaultValue>-- Choose --</option>
                            <option value="Female">Female</option>
                            <option value="Male">Male</option>
                            <option value="Other">Other</option>
                            <option value="PreferNotToSay">I prefer not to say</option>
                        </select><br />


                        <label htmlFor="phone_number">Phone*</label>
                        <input onChange={handleChangeInput} id="phone" name="phone_number" type="text" placeholder="Fill your phone here" />

                        {/* <br />
                        <label htmlFor="address">Address*</label>
                        <input onChange={handleChangeInput} id="address" name="address" type="text" placeholder="123 John St."
                            required />
                        <div id="postalCodeError" ></div> */}

                        <label htmlFor="postal_code">Postal Code*</label>
                        <input onChange={handleChangeInput} id="postal_code" name="postal_code" type="text" placeholder="A1A 2B2" pattern="^[A-Z][0-9][A-Z][\s][0-9][A-Z][0-9]$"
                            required />
                        <div id="postalCodeError" ></div>

                        <label htmlFor="city">City*</label>
                        <select onChange={handleChangeInput} id="city" name="city" type="text" value={formData.city}>
                            <option value="choose" disabled>---</option>
                            <option value="Toronto">Toronto</option>
                            {/* <option value="Mississauga">Mississauga</option>
                                <option value="Ajax">Ajax</option> */}
                        </select>

                        <div id="chooseHeight" >
                            <label htmlFor="height">Height* <small>(For safety reasons, please enter the current height of children when choosing bikes for them)</small> </label><br />
                            <select onChange={handleChangeHeight2} id="height_ft" name="height_ft" value={formData.height_ft}>
                                <option value="choose" defaultValue>---</option>
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                            </select>ft
                            <select onChange={handleChangeHeight3} id="height_in" name="height_in" defaultValue="160" value={formData.height_in}>
                                <option value="choose" defaultValue>---</option>
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                            </select> in

                            <b> OR [metric]</b>
                            <input onChange={handleChangeHeight1} onInput={handleChangeHeight1} id="height_cm" name="height_cm" className="heightCm" type="number" min="66" max="200"
                                value={formData.height_cm} required /> cm<br /><br />
                            <label htmlFor="calculatedBikeSize">Your bike size (automatically calculated from height):</label>

                            <input readOnly type="text" id="calculatedBikeSize"
                                name="bike_size" value={formData.bike_size} />
                        </div>


                        <p>You need a bike for*: <small>(Check all that apply)</small></p>

                        <input onChange={handleChangeInput} type="checkbox" id="commute to work/school" name="needBikeFor" />
                        <label htmlFor="commute to work/school">Commuting to work/school</label><br />
                        <input onChange={handleChangeInput} type="checkbox" id="running errands and shopping" name="needBikeFor" />
                        <label htmlFor="running errands and shopping">Running errands and shopping</label><br />
                        <input onChange={handleChangeInput} type="checkbox" id="physical health" name="needBikeFor" />
                        <label htmlFor="physical health">Improving my physical health</label><br />
                        <input onChange={handleChangeInput} type="checkbox" id="mental health" name="needBikeFor" />
                        <label htmlFor="mental health">Improving my mental health</label><br />
                        <input onChange={handleChangeInput} type="checkbox" id="recreation" name="needBikeFor" />
                        <label htmlFor="recreation">Recreation</label><br />
                        <input onChange={handleChangeInput} type="checkbox" id="trail and mountain biking" name="needBikeFor" />
                        <label htmlFor="trail and mountain biking">Trail riding and mountain biking</label><br />
                        <input onChange={handleChangeInput} type="checkbox" id="friends" name="needBikeFor" />
                        <label htmlFor="friends">Cycling with friends</label><br />
                        <input onChange={handleChangeInput} type="checkbox" id="family" name="needBikeFor" />
                        <label htmlFor="family">Cycling with family</label><br />
                        <input onChange={handleChangeInput} type="checkbox" id="food" name="needBikeFor" />
                        <label htmlFor="food_delivery">Food delivery (e.g. Uber)</label><br />
                        <input onChange={handleChangeInput} type="checkbox" id="trading_bike" name="needBikeFor" />
                        <label htmlFor="trading_bike">Trading another bike</label><br />
                        <input onChange={handleChangeInput} type="checkbox" id="other" name="needBikeFor" />
                        <label htmlFor="other">Other</label><br />

                        <p id="whyNeed" >
                            Tell us more about why yourself and how you will use a bike: (optional)<br />
                            <textarea onChange={handleChangeInput} id="reason" name="reason"></textarea>
                        </p>

                        <p >
                            I would like to receive email updates from: <br />
                            <input type="checkbox" name="agreeToEmail" id="agreeToEmail" checked readOnly />
                            <label htmlFor="agreeToEmail">BikeMatchWCN.com to confirm my sign up, bike reservation details and updates on the bikes available to me.</label><br />
                            <input onChange={handleChangeInput} type="checkbox" id="consent_wcn" name="consent_wcn" />
                            <label htmlFor="consent_wcn">Women's Cycling Network (WCN)</label><br />
                            <input onChange={handleChangeInput} type="checkbox" id="consent_local_group" name="consent_local_group" />
                            <label htmlFor="beContacteconsent_local_groupLocalCheckbox">Local Cycling Advocacy Group</label><br />
                            {/* <input onChange={handleChangeInput} type="checkbox" id="consent_local_bike_hub" name="consent_local_bike_hub" />
                            <label htmlFor="consent_local_bike_hub">Local Bike Hub</label><br /> */}
                            <br />
                            I would like to:<br />
                            <input onChange={handleChangeInput} type="checkbox" id="consent_learn_to_ride" name="consent_learn_to_ride" />
                            <label htmlFor="consent_learn_to_ride">participate in the Learn to Ride Program.</label><br />
                            <input onChange={handleChangeInput} type="checkbox" id="consent_bike_sills" name="consent_bike_skills" />
                            <label htmlFor="consent_bike_skills">improve my bike riding skills</label><br />
                            <input onChange={handleChangeInput} type="checkbox" id="consent_basic_mechanics" name="consent_basic_mechanics" />
                            <label htmlFor="consent_basic_mechanics">learn basic bike mechanics</label><br /><br />
                            <input type="checkbox" id="checkTerms" name="acceptTerms" onChange={enableAddUser} />
                            <label htmlFor="checkTerms"><b>I agree to the
                                <a className="simpleLink" href="#termsAndConditions" > terms and conditions</a> and the <a target="_blank" rel="noreferrer" className="simpleLink" href="https://docs.google.com/document/d/12IsZfnIHVchu0PjlnOYXysGPX1UthnuLl6yirTBpIPo/edit?usp=sharing"> waiver</a>.</b>
                                (you must check this box sign up for a bike).</label>
                        </p>

                        <div className="wrapper centering">
                            <p className="errorBox">
                                <label id="errorBox" name="erroBox">{errorText}</label>
                            </p>
                            {canAddUser
                                ? <button id="addUserButton" onClick={addUser} type="button" className="otherButton"> Sign Up</button>
                                : <button type="button" id="fakeButton" className="fakeButton">Sign Up <br />(accept the terms to activate)</button>}
                        </div>

                    </form>
                </div>

                <TermsAndConditions />
            </section>



        </>
    )
}
export default AddUser;