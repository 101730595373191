import { useState, useEffect } from 'react';

// IMAGES:
import step from './images/step.png';
import horizontal from "./images/horizontal.png";
import sloped from "./images/sloped.png";
import noFileLoaded from "./images/noFileLoaded.png";
import bikeWheelSizeHelp from "./images/bikeWheelSizeHelpbig.png";
import markingsOnTire from "./images/markingsOnTire.png";
import bikeSizeHelp from "./images/bikeSizeHelpFinal.png";

const EditBike = ({ user, users, superUser, logout }) => {
  let [bikes, setBikes] = useState();
  let [selectedBike, setSelectedBike] = useState();
  let [bikeStatusChosen, setBikeStatusChosen] = useState();
  let [locations, setLocations] = useState();

  const [showHelp, setShowHelp] = useState();
  function handleShowHelp(event) {
    if (showHelp)
      setShowHelp(false);
    else
      setShowHelp(true);
  }

  let fileReader = new FileReader();
  //eslint-disable-next-line
  let filterType = /^(?:image\/bmp|image\/cis\-cod|image\/gif|image\/ief|image\/jpeg|image\/jpeg|image\/jpeg|image\/pipeg|image\/png|image\/svg\+xml|image\/tiff|image\/x\-cmu\-raster|image\/x\-cmx|image\/x\-icon|image\/x\-portable\-anymap|image\/x\-portable\-bitmap|image\/x\-portable\-graymap|image\/x\-portable\-pixmap|image\/x\-rgb|image\/x\-xbitmap|image\/x\-xpixmap|image\/x\-xwindowdump)$/i;
  fileReader.onload = function (event) {
    let image = new Image();

    image.onload = function () {
      //document.getElementById("original-Img").src=image.src;
      let canvas = document.createElement("canvas");
      let context = canvas.getContext("2d");

      if ((image.width > 400) | (image.heigth > 400)) {
        //let proportion = image.width / image.height;
        //console.log("proportion:" + proportion);
        let redux = image.width / 300;
        canvas.width = image.width / redux;
        canvas.height = image.height / redux;
      } else {
        canvas.width = image.width;
        canvas.height = image.height;
      }
      context.drawImage(
        image,
        0,
        0,
        image.width,
        image.height,
        0,
        0,
        canvas.width,
        canvas.height
      );

      document.getElementById("outputImage").src = canvas.toDataURL();
      document.getElementById("outputImage").style.display = "block";
      let localData = { ...selectedBike };
      localData.picture = document.getElementById("outputImage").src.split('base64,')[1];
      //console.log(localData.picture)
      setSelectedBike(localData)

    };
    image.src = event.target.result;
  };

  let loadImageFile = function () {
    let uploadImage = document.getElementById("loadPicture");

    //check and retuns the length of uploded file.
    if (uploadImage.files.length === 0) {
      return;
    }

    //Is Used for validate a valid file.
    let uploadFile = document.getElementById("loadPicture").files[0];
    if (!filterType.test(uploadFile.type)) {
      alert("Please select a valid image.");
      return;
    }

    fileReader.readAsDataURL(uploadFile);
  };

  useEffect(() => {
    loadBikes();
  }, []);

  function loadBikes() {
    let data = { email: 'all' };
    fetch("/getAllBikesNoPics", {
      method: "POST",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" }
    })
      .then(res => {
        let response = res;
        //console.log(response);
        return (response.json());
      })
      .then(response => {
        //console.log('Reloaded Bikes:');
        //console.log(response);
        setBikes(response.filter(b => user.type === "superAdmin" || b.location === user.location));
      })
      .catch(
        e => {
          //console.log("Error:" + e)
          alert("There was an error connecting to our server to load the bikes. Please logout, login and try again. Error: " + e)
          logout()
        }
      )
  }

  useEffect(() => {
    loadBikesSelect();
  }, [bikeStatusChosen]);

  function locationName(id) {
    if (locations) {
      let loc = locations.filter(l => l.id === id)[0];
      if (loc)
        return loc.name;
      else
        return "No location name available."
    }
    else
      return "No locations loaded."
  }

  function loadBikesSelect() {
    if (selectedBike)
      return

    if (bikes) {
      let selectBike = document.getElementById('bikeIdBox');
      selectBike.innerHTML = '';

      let opt = document.createElement('option');
      opt.value = 'na';
      opt.innerHTML = '-- Choose --';
      selectBike.appendChild(opt);
      bikes.filter(bike => bike.status == bikeStatusChosen).map(b => {
        let opt = document.createElement('option');
        opt.value = b.id;
        let s = b.status == "Donated" ? "Delivered" : b.status;
        opt.innerHTML = b.tag + " (id : " + b.id + ") " + s + " @ " + locationName(b.location);
        selectBike.appendChild(opt);
        return true
      })
    }
  }

  function setSelected(event) {
    let id = event.target.value
    //console.log(id)
    //console.log(bikes)
    let bike = bikes.filter(b => b.id === parseInt(id))[0]
    //console.log(JSON.stringify(bike))
    fetch('/getBikeWithPic', {
      'method': 'POST',
      'headers': {
        'Content-Type': 'application/json'
      },
      'body': JSON.stringify(bike)
    }).then(res => {
      let response = res;

      return (response.json());
    }).then(res => {
      let response = res
      //console.log('INSIDE RESPONSE')
      //console.log(response)
      setSelectedBike(response[0])
    })

  }

  useEffect(() => {
    fetch("/getAllLocations")
      .then(res => {
        let response = res;
        //console.log(response);
        return (response.json());
      })
      .then(response => {
        let r = response;
        //console.log(r);
        setLocations(r);
      }
      ).catch(
        e => {
          //console.log("Error:" + e)
          alert("There was an error connecting to our server to load the bikes. Please logout, login and try again. Error: " + e)
          logout()
        }
      )
  }, []);

  useEffect(() => {
    loadLocations();
  }, [selectedBike, bikeStatusChosen]);
  function loadLocations() {
    fetch("/getAllLocations")
      .then(res => {
        let response = res;
        //console.log(response);
        return (response.json());
      })
      .then(response => {

        if (selectedBike) {
          let ls = response;


          let locs = document.getElementById("location");
          locs.innerHTML = '';
          let option = document.createElement("option");
          option.text = "-- Choose --";
          locs.add(option);
          locs.selectedIndex = "0";
          if (ls.length > 0) {
            ls.forEach(l => {
              let option = document.createElement("option");
              option.text = l.id + " - " + l.name;
              option.value = l.id;
              locs.add(option);
            })
          }


          setLocations(ls);
        }

      })

      .catch(
        e => {
          //console.log("Error:" + e)
          alert("There was an error connecting to our server to load the bikes. Please logout, login and try again. Error: " + e)
          logout()
        }
      )
  }

  // useEffect(() => {
  //   loadLocationOptions();
  // }, []);
  // function loadLocationOptions() {
  //   if (selectedBike) {
  //     let locs = document.getElementById("location");
  //     locs.innerHTML = '';
  //     let option = document.createElement("option");
  //     option.text = "-- Choose --";
  //     locs.add(option);
  //     locs.selectedIndex = "0";
  //     if (locations.length > 0) {
  //       locations.forEach(l => {
  //         let option = document.createElement("option");
  //         option.text = l.id + " - " + l.name;
  //         option.value = l.id;
  //         locs.add(option);
  //       })
  //     }
  //   }
  // }

  function handleChangeInput(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    //console.log(target.type)
    if (target.type === 'checkbox') {
      let boxes = document.getElementsByName([name])
      //console.log(boxes)
      for (let i = 0; i < boxes.length; i++) {
        boxes[i].checked = false;
      }
    }

    //console.log(event.target);
    //console.log(value);
    //console.log(name);

    let localData = { ...selectedBike };
    localData[name] = value;
    //console.log(localData);
    setSelectedBike(localData);
  }

  function editBike() {
    if (!selectedBike) {
      alert('Please choose a bike first.')
      return
    }
    let confirm = window.confirm("Are you sure you want to change this bike? This action cannot be reversed.")

    if (!confirm)
      return
    //console.log('editing bike...')

    let formData = { ...selectedBike }
    formData.adminUser = user.email

    fetch('/updateBike', {
      'method': 'POST',
      'headers': {
        'Content-Type': 'application/json'
      },
      'body': JSON.stringify(formData)
    }).then(response => {
      if (response.status === 200) {
        alert('bike Updated.');
        setSelectedBike();
        setBikeStatusChosen();
      } else {
        // read the response body as plain text
        response.text().then(text => {
          //console.log('Error updating bike: ' + text);
          alert("There was an error updating the bike. Please try again. Error:" + text);

        });
      }
    }).catch(error => {
      //console.log(error);
      alert("There was an error updating the bike. Error: " + error);

    });



  }


  function download_bikes_csv() {
    //** ATTENTION!! No IMAGES ARE DOWNLOADED!! */
    let csv = "";
    let createHeader = true;

    if (!bikes || !locations)
      return

    function testFieldBike(a) {
      if (a === "picture")
        return false;
      if (a === "donor_name")
        return false;
      return true
    }
    //console.log(bikes)

    bikes.map(bike => {

      if (createHeader) {
        for (let item in bike) {
          if (testFieldBike(item))
            csv += item + ",";
        }
        csv += "\n";
        createHeader = false;
      }

      if (bike.status === "Deleted") {
        return true
      }
      for (let item in bike) {
        if (testFieldBike(item)) {
          if (item === "date_included") {
            let dateText = new Date(bike.date_included).toLocaleDateString();
            csv += dateText + ",";
          }
          else if (item === "location") {
            let localName = locations.filter(l => l.id === parseInt(bike[item]))[0];
            if (localName)
              localName = localName.name;
            else
              localName = 'No name available.';
            csv += localName + ",";

          }
          else if (item === "log" || item === "description" || item === "tag") {
            let freeText = '"' + bike[item] + '"';
            //Removes special characters:
            freeText = '"' + freeText.replace(/[`~#$%^&*_|+=?'"<>]/gi, '') + '"';

            csv += freeText + ",";
          }
          else {
            csv += bike[item] + ",";
          }
        }
      }
      csv += "\n";
      return true
    })


    let hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
    hiddenElement.target = "_blank";

    let today = new Date().toLocaleDateString();
    hiddenElement.download = "bikeMatchWCNdata-" + today + ".csv";
    hiddenElement.click();

  }

  function handleStatusChosen(event) {
    let status = event.target.value;
    setBikeStatusChosen(status);
    setSelectedBike(null);
  }

  return (

    <>
      <section id="content" className="wrapper">
        <h3>Edit bike</h3>


        <button className="showHelp" type="button" onClick={handleShowHelp}>{showHelp ? "-" : "?"}</button>
        <div className="contentCard wrapper">
          {/* add checkboxes for the bike status */}

          <form id="bikeData">
            <label htmlFor="bikeIdBox">Choose a bike status:</label><br />
            <select id="bikeStatusChosen" name="bikeStatusChosen" onChange={handleStatusChosen} value={bikeStatusChosen ? bikeStatusChosen : "choose"}>
              <option value="choose">-- Choose --</option>
              <option value="Reserved">Reserved</option>
              <option value="Waiting for Release">Waiting for Release</option>
              <option value="Available">Available</option>
              <option value="Donated">Delivered</option>
              <option value="For Parts Only">For Parts Only</option>
              <option value="Needs Repair">Needs Repair</option>
              <option value="Deleted">Deleted</option>
              <option value="Waiting for Approval">Waiting for Approval</option>
            </select>
            <label htmlFor="bikeIdBox">Choose a bike:</label><br />
            <select id="bikeIdBox" name="bikeIdBox" onChange={setSelected} value={selectedBike ? selectedBike.id : "choose"}>
              <option value="choose">-- Loading Bikes... --</option>
            </select>
            {selectedBike ?
              <>

                {user.type === "superAdmin" ?
                  <>
                    <label htmlFor="locationBox">Location*</label><br />
                    <select onChange={handleChangeInput} id="location" name="location" type="select" width="100%" required value={selectedBike ? selectedBike.location : user.location}>
                      <option>-- Choose an option --</option>
                    </select>
                  </>
                  : <>
                    <label htmlFor="locationBox">Location*</label><br />
                    <select disabled onChange={handleChangeInput} id="location" name="location" type="select" width="100%" required value={selectedBike ? selectedBike.location : user.location}>
                      <option>-- Choose an option --</option>
                    </select>
                  </>
                }
                <br /><br />
                <label htmlFor="id">Internal Id</label><br />
                <textarea className="grayBackground textAreaSmall" rows="1" readOnly value={selectedBike ? selectedBike.id : 'na'}></textarea>
                <br /><br />


                <label htmlFor="type">Bike type</label><br />

                <select onChange={handleChangeInput} id="type" name="type" value={selectedBike ? selectedBike.type : 'na'}>
                  <option value="na">-- Choose --</option>
                  <option value="City">City</option>
                  <option value="Hybrid">Hybrid</option>
                  <option value="Mountain">Mountain</option>
                  <option value="Road">Road</option>
                  <option value="Fold">Fold</option>
                  <option value="Fat">Fat</option>
                </select>


                <label htmlFor="bikeFrameType">Bike frame type</label><br />
                {showHelp ? <div id="frameTypeImages">
                  <img className="frameTypeImages" src={step} alt="type of bike frames: step-through" />
                  <img className="frameTypeImages" src={horizontal} alt="type of bike frames: horizontal " />
                  <img className="frameTypeImages" src={sloped} alt="type of bike frames: sloped" />
                </div> : null}
                <select onChange={handleChangeInput} id="bikeFrameType" name="frame" value={selectedBike ? selectedBike.frame : "choose"}>
                  <option value="choose" defaultValue>-- Choose --</option>
                  <option value="Sloped">Sloped</option>
                  <option value="Horizontal">Horizontal</option>
                  <option value="Step-through">Step-through</option>
                  <option value="Folding">Folding</option>
                </select>


                <label htmlFor="bikeWheelSize">Bike wheel size</label>
                {showHelp ?
                  <div id="showHelpBikeWheelSize" >
                    <img className="frameTypeImages" alt="bike wheel size help" width="40%" src={bikeWheelSizeHelp} />
                    <img className="frameTypeImages" alt="bike wheel size markings on tire" width="30%" src={markingsOnTire} />
                  </div> : null}
                <select onChange={handleChangeInput} id="bikeWheelSize" name="wheel_size" value={selectedBike ? selectedBike.wheel_size : "na"}>
                  <option value="na" defaultValue>-- Choose --</option>
                  <option value="10">10''</option>
                  <option value="12">12''</option>
                  <option value="14">14''</option>
                  <option value="16">16''</option>
                  <option value="18">18''</option>
                  <option value="20">20''</option>
                  <option value="24">24''</option>
                  <option value="26">26''</option>
                  <option value="27">27''</option>
                  <option value="27.5">27.5''</option>
                  <option value="29">29''</option>
                  <option value="700">700c</option>
                  <option value="32">32''</option>
                  <option value="36">36''</option>
                </select>

                <div>
                  <label htmlFor="donorBikeSize">Bike size</label>


                  {showHelp ?
                    <div id="showHelpBikeSize" name="showHelpBikeSize" >
                      <img id="helpBikeSize" alt="bike size help" src={bikeSizeHelp} />
                    </div> : null}
                  <select onChange={handleChangeInput} id="donorBikeSize" name="size" value={selectedBike ? selectedBike.size : "choose"}>
                    <option value="choose" defaultValue>-- Choose --</option>
                    <option value="Toddler">Toddler</option>
                    <option value="Kids12">Kids 12''</option>
                    <option value="Kids14">Kids 14''</option>
                    <option value="Kids16">Kids 16''</option>
                    <option value="Kids20">Kids 20''</option>
                    <option value="Kids24">Kids 24''</option>
                    <option value="Kids26">Kids 26''</option>
                    <option value="XSmall">XSmall</option>
                    <option value="Small">Small</option>
                    <option value="Medium">Medium</option>
                    <option value="Large">Large</option>
                    <option value="XLarge">XLarge</option>
                    <option value="XXLarge">XXLarge</option>
                  </select>
                </div>

                <label htmlFor="bikeCondition" id="bikeConditionText">Bike status</label><br />
                <select onChange={handleChangeInput} id="bikeCondition" name="status" value={selectedBike ? selectedBike.status : "choose"}>
                  {/* <option value="choose" defaultValue>-- Choose --</option> */}

                  {selectedBike ? selectedBike.status !== "Donated" ?
                    <>
                      <option value="Reserved">Reserved</option>
                      <option value="Waiting for Release">Waiting for Release</option>
                      <option value="Available">Available</option>
                      {/* <option value="Donated">Delivered</option> */}
                      <option value="For Parts Only">For Parts Only</option>
                      <option value="Needs Repair">Needs Repair</option>
                      <option value="Deleted">Deleted</option>
                      {/* <option value="Waiting for Approval">Waiting for Approval</option> */}
                    </>
                    : <><option value="Donated">Delivered</option><option value="Deleted">Deleted</option></> : <></>
                  }


                </select>


                <div>
                  <label htmlFor="imageBike" id="loadPictureText"> Bike Photo</label>
                  <input id="loadPicture" type="file" onChange={loadImageFile} />
                  <br />

                </div>
                <img className="frameTypeImages" id="outputImage" alt="uploaded bike" src={selectedBike ? `data:image/jpeg;base64,${selectedBike.picture}` : noFileLoaded} />

                <div>
                  <p id="describeBike">
                    <label htmlFor="bikeDescription">Bike description</label><br />
                    <textarea onChange={handleChangeInput} id="description" name="description" value={selectedBike ? selectedBike.description : ""}></textarea>
                  </p>

                </div>

                <div>
                  <p id="log">
                    <label htmlFor="bikeLog">Log (read only - cannot be changed):</label><br />
                    <textarea name="bikeLog" className="grayBackground" rows="6" readOnly value={selectedBike ? selectedBike.log : ""}></textarea>
                  </p>




                </div>

                <div className="wrapper centering">
                  <button id="editBikeButton" type="button" className="otherButton" onClick={editBike}>
                    Save Edited Bike
                  </button>


                </div>
                <p>
                  <label id="errorBox" name="erroBox"></label>
                </p>

              </> : null}
          </form>
        </div>

        {superUser() ?
          bikes && locations
            ?
            <div className="wrapper centering">
              <button id="createCSVButton" type="button" onClick={download_bikes_csv} className="otherButton">
                Download Bikes DB (CSV)
              </button>
            </div>
            : <div className="wrapper centering">Loading bikes...</div>
          : null
        }



      </section >

    </>
  )
}
export default EditBike;