import './styles/style.css';
import MainPage from './MainPage.js';

import AddUser from './AddUser.js';
import AddPickupDate from './AddPickupDate.js';
import AddAdminUser from './AddAdminUser.js';
import AddBike from './AddBike.js';
import ViewBikes from './ViewBikes.js';
import Login from './Login.js';
import EditUser from './EditUser.js';
import EditBike from './EditBike.js';
import ViewBikesAdmin from './ViewBikesAdmin';
import Print from './Print.js';
import Stats from './Stats.js';
import ham_menu from './images/ham_menu.png';
import ham_menu_closed from './images/ham_menu_closed.png';
// import ConfirmCancelMatch from './ConfirmCancelMatch.js';

import Footer from './Footer.js';

import { useState, useEffect } from 'react';
import wcnlogo from './images/WCNlogo.png';



function App() {

  //PAUSING THE PROGRAM
  let [isPaused, setIsPaused] = useState(false);
  // let today = new Date();
  // let pauseProgram = new Date("February 26, 2024 00:00:00");
  // let startAgain = new Date("March 4, 2024 00:00:00");
  // useEffect(() => {
  //   if (today > pauseProgram && today <= startAgain) {
  //     setIsPaused(true)
  //   } else {
  //     setIsPaused(false)
  //   }
  // }, []);


  // let sender = "contact@garagelab.ca";
  let sender = "bikematch@bikematchwcn.com";
  //let replyTo = "bikematchwcn@gmail.com";
  const sendEmail = (recipient, subject, text, html, replyTo) => {
    if (!isPaused) {

      let data = {
        recipient: recipient,
        sender: sender,
        subject: subject,
        text: text,
        replyTo: replyTo,
        html: html
      };

      fetch("/sendEmail", {
        method: "POST",
        body: JSON.stringify(data),
        headers: { "Content-Type": "application/json" }
      })
        .then(res => {
          let response = res;
          return (response.text());

        })
        .then(res => {
          //let response = res;
          //console.log(response);
          //alert(response);
        })
        .catch(
          e => {
            //console.log("Error:" + e)
            alert("There was an error connecting to our server to send emails. Could you logout, login and try again?")
            logout()
          }
        )
    } else {
      console.log("Emails not being sent. Program is paused.")
    }
  }



  let [currentPage, setCurrentPage] = useState('mainPage');
  function mainPage() {
    setCurrentPage('mainPage');
  }
  function addBikePage() {
    setCurrentPage('addBikePage');
  }
  function addDatePage() {
    setCurrentPage('addDatePage');
  }
  function addUserPage() {
    setCurrentPage('addUserPage');
  }
  function addAdminUserPage() {
    setCurrentPage('addAdminUserPage');
  }
  function viewBikesPage() {
    setCurrentPage('viewBikesPage');
  }
  function printPage() {
    setCurrentPage('printPage');
  }
  function statsPage() {
    setCurrentPage('statsPage');
  }

  function loginPage() {
    if (user) {
      setCurrentPage('loginPage');

    }
    else {
      setCurrentPage('mainPage');

    }
  }

  function logout() {
    if (user) {
      setUser({})
      window.localStorage.clear();
      setCurrentPage('mainPage')
    }
  }

  function viewBikesAdminPage() {
    setCurrentPage('viewBikesAdminPage');
  }
  function editBikePage() {
    setCurrentPage('editBikePage');
  }
  function editUserPage() {
    setCurrentPage('editUserPage');
  }

  const [user, setUser] = useState({})
  useEffect(() => {
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      //console.log('found user: ',foundUser);
      setUser(foundUser);
    } else {
      //console.log('Did not find user');
      //alert('Did not find user.')
    }
  }, []);


  useEffect(() => {
    window.scrollTo(0, 0)
    //console.log('scroll top')
  }, [currentPage])

  useEffect(() => {
    localStorage.setItem('user', JSON.stringify(user));
  }, [user]);

  let [bikes, setBikes] = useState();
  useEffect(() => {
    loadBikes();
  }, []);



  function loadBikes() {
    let data = { email: 'all' };
    fetch("/getAllBikesNoPics", {
      method: "POST",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" }
    })
      .then(res => {
        let response = res;
        //console.log(response);
        return (response.json());
      })
      .then(response => {
        //console.log('APP.js Bikes:');
        //console.log(response);
        setBikes(response);
      })

      .catch(
        e => {
          //console.log("Error:" + e)
          alert("There was an error connecting to our server to load the bikes. Could you logout, login and try again?")
          logout()
        }

      )


  }


  let [showHideMenu, setShowHideMenu] = useState(false);
  function showHideMenuToggle() {
    setShowHideMenu(!showHideMenu);
  }
  useEffect(() => {
    setShowHideMenu(false);
  }, [currentPage]);

  function superUser() {
    if (!user)
      return false;
    if (user.type === "superAdmin")
      return true;
    return false;
  }
  let editBikeAvailable = true;

  //console.log(user)
  return (
    <div className="App">

      <menu>
        <div className="menuHome">
          <button onClick={mainPage} className="menuHomeButton">
            <img className="menuHomeImage" src={wcnlogo} alt="WCN logo" />
          </button>
        </div>
        <div className="menuAll">
          <div className="regularMenu">
            {!user.email && !isPaused ? <button onClick={addUserPage} className="MainButtonsButton">Sign Up</button> : null}
            {user.email && user.type === "recipient" ? <button onClick={viewBikesPage} className="MainButtonsButton">View Bikes</button> : null}
            {(user.type === 'admin' || user.type === "superAdmin") ? <button onClick={viewBikesAdminPage} className="adminButton">View All Bikes</button> : null}
            {user.type === 'admin' || user.type === "superAdmin" || user.type === "donor" ? <button onClick={addBikePage} className="adminButton">Add / Release Bikes</button> : null}
            {(user.type === 'admin' || user.type === "superAdmin") && editBikeAvailable ? <button onClick={editBikePage} className="adminButton">Edit Bike</button> : null}
            {(user.type === 'admin' || user.type === "superAdmin") ? <button onClick={editUserPage} className="adminButton">Edit User</button> : null}
            {(user.type === 'admin' || user.type === "superAdmin") ? <button onClick={addDatePage} className="adminButton">Add Event / Date</button> : null}
            {(user.type === 'admin' || user.type === "superAdmin") ? <button onClick={addAdminUserPage} className="adminButton">Add Admin</button> : null}
            {(user.type === 'admin' || user.type === "superAdmin") ? <button onClick={printPage} className="adminButton">Schedule</button> : null}
            {(user.type === 'admin' || user.type === "superAdmin") ? <button onClick={statsPage} className="adminButton">Stats</button> : null}
            {/* {(user.type === 'admin' || user.type==="superAdmin") ? <button onClick={confirmCancelMatchPage} className="adminButton">Confirm / Cancel</button> : null} */}
            {!isPaused ? user.email
              ? <button onClick={logout} className="loginButton">Logout {user.first_name}</button>
              : <button onClick={loginPage} className="loginButton">Login</button>
              : null
            }
          </div>
          <button className="MainButtonsButton Xhidden" onClick={showHideMenuToggle}>
            {!isPaused ? showHideMenu
              ? null
              : <img alt="ham_menu" className="ham_menu" src={ham_menu} />
              : null}
          </button>
          <>
            {showHideMenu ?
              <div className="hamburger_menu_options">
                <button className="MainButtonsButton Xhidden" onClick={showHideMenuToggle}>
                  {showHideMenu
                    ? <img alt="ham_menu_closed" className="ham_menu" src={ham_menu_closed} />
                    : null
                  }
                </button>
                {!user.email && !isPaused ? <button onClick={addUserPage} className="MainButtonsButton Xhidden">Sign Up</button> : null}
                {user.email && user.type === "recipient" ? <button onClick={viewBikesPage} className="MainButtonsButton Xhidden">View Bikes</button> : null}
                {(user.type === 'admin' || user.type === "superAdmin") ? <button onClick={viewBikesAdminPage} className="adminButton Xhidden">View All Bikes</button> : null}
                {(user.type === 'admin' || user.type === "superAdmin") || user.type === "donor" ? <button onClick={addBikePage} className="adminButton Xhidden">Add / Release Bikes</button> : null}
                {(user.type === 'admin' || user.type === "superAdmin") && editBikeAvailable ? <button onClick={editBikePage} className="adminButton">Edit Bike</button> : null}
                {(user.type === 'admin' || user.type === "superAdmin") ? <button onClick={editUserPage} className="adminButton Xhidden">Edit User</button> : null}
                {(user.type === 'admin' || user.type === "superAdmin") ? <button onClick={addDatePage} className="adminButton Xhidden">Add Event / Date</button> : null}
                {(user.type === 'admin' || user.type === "superAdmin") ? <button onClick={addAdminUserPage} className="adminButton Xhidden">Add Admin</button> : null}
                {(user.type === 'admin' || user.type === "superAdmin") ? <button onClick={printPage} className="adminButton Xhidden">Schedule</button> : null}
                {(user.type === 'admin' || user.type === "superAdmin") ? <button onClick={statsPage} className="adminButton Xhidden">Stats</button> : null}

                {/* {(user.type === 'admin' || user.type==="superAdmin") ? <button onClick={confirmCancelMatchPage} className="adminButton">Confirm / Cancel</button> : null} */}
                {!isPaused ? user.email
                  ? <button onClick={logout} className="loginButton Xhidden">Logout {user.first_name}</button>
                  : <button onClick={loginPage} className="loginButton Xhidden">Login</button>
                  : null}
              </div>
              : null
            }
          </>
        </div>

      </menu>



      {currentPage === "mainPage" ?
        <MainPage isPaused={isPaused} /> : null}
      <div className="allContent">

        {currentPage === "viewBikesAdminPage" ? <ViewBikesAdmin user={user} setUser={setUser} bikes={bikes} logout={logout} /> : null}
        {currentPage === "addBikePage" ? <AddBike user={user} sendEmail={sendEmail} logout={logout} /> : null}
        {currentPage === "addUserPage" ? <AddUser setUser={setUser} sendEmail={sendEmail} setCurrentPage={setCurrentPage} logout={logout} /> : null}
        {currentPage === "addAdminUserPage" ? <AddAdminUser user={user} logout={logout} /> : null}
        {currentPage === "addDatePage" ? <AddPickupDate user={user} logout={logout} /> : null}
        {currentPage === "viewBikesPage" ? <ViewBikes user={user} setUser={setUser} bikes={bikes} sendEmail={sendEmail} logout={logout} /> : null}
        {currentPage === "loginPage" ?
          !user.email ?
            <Login user={user} setUser={setUser} setCurrentPage={setCurrentPage} logout={logout} />
            : <>
              <h3>You are logged in.</h3>
              {(user.type === 'admin' || user.type === "superAdmin") ?
                <div className="main centering">
                  <p>You are an <b>administrator</b>.</p>
                  <p>You can <b>View All Bikes</b>, <b>Add Bike</b>, <b>Edit Bike</b> or <b>Edit User</b> by clicking on the buttons.</p>
                </div> : null}

            </>
          : null}
        {currentPage === "editBikePage" ? <EditBike user={user} bikes={bikes} superUser={superUser} logout={logout} /> : null}
        {currentPage === "editUserPage" ? <EditUser user={user} bikes={bikes} superUser={superUser} logout={logout} /> : null}
        {currentPage === "printPage" ? <Print user={user} bikes={bikes} logout={logout} sendEmail={sendEmail} /> : null}
        {currentPage === "statsPage" ? <Stats user={user} logout={logout} /> : null}

        {/* {currentPage === "confirmCancelMatchPage" ? <ConfirmCancelMatch bikes={bikes} /> : null} */}

      </div>
      <Footer />
    </div>
  );
}

export default App;
