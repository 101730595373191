import { useState, useEffect } from 'react';
import './Stats.css';

const Stats = ({ user, logout }) => {
  let [year, setYear] = useState('All');

  let [locations, setLocations] = useState([]);
  useEffect(() => {
    loadLocations();
  }, []);
  function loadLocations() {
    fetch("/getAllLocations")
      .then(res => {
        let response = res;
        
        return (response.json());
      })
      .then(response => {
        let final = response;
        
        setLocations(final);

      })

      .catch(
        e => {
         
          alert("There was an error connecting to our server to load the locations. Please logout, login and try again. Error: " + e)
          logout()
        }
      )
  }

  let [users, setUsers] = useState([]);
  let [newUsers, setNewUsers] = useState([]);
  
  useEffect(() => {
    loadUsers();
  }, []);
  useEffect(() => {
    loadUsers();
  }, [year]);
  function loadUsers() {
    let data = {};
    fetch("/getAllUsers", {
      method: "POST",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" }
    })
      .then(res => {
        let response = res;
        
        return (response.json());
      })
      .then(response => {
        let final = response;
        setUsers(final.filter(u=>u.status!=="Deleted"&&u.type!=="admin"&&u.type!=="superAdmin"));
        if (year === "All") {
          setNewUsers(final.filter(u=>u.status!=="Deleted"&&u.type!=="admin"&&u.type!=="superAdmin"));
          return;
        } else {
          setNewUsers(final.filter(u => {
            let yearIncluded = new Date(u.date_included).getFullYear();
            return (yearIncluded.toString() === year.toString() && u.status!=="Deleted"&&u.type!=="admin"&&u.type!=="superAdmin")
          }))
        }
      })
      .catch(
        e => {
          
          alert("There was an error connecting to our server to load the users. Please logout, login and try again. Error: " + e)
          logout()
        }
      )


  }

  let [bikes, setBikes] = useState();
  useEffect(() => {
    loadBikes();
  }, []);
  useEffect(() => {
    loadBikes();
  }, [year]);
  function loadBikes() {
    let data = {};
    fetch("/getAllBikesNoPics", {
      method: "POST",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" }
    })
      .then(res => {
        let response = res;
        
        return (response.json());
      })
      .then(response => {
        let final = response;
        if (year === "All") {
          setBikes(final);
          return;
        } else {
          
          setBikes(final.filter(b => {
            let yearIncluded = new Date(b.date_included).getFullYear();
          
            return (yearIncluded.toString() === year.toString())
          }))
        }

      })

      .catch(
        e => {
          alert("There was an error connecting to our server to load the bikes. Please logout, login and try again. Error: " + e)
          logout()
        }
      )


  }

  const [allStats, setAllStats] = useState({});

  useEffect(() => {
    loadStats();
  }, []);
  useEffect(() => {
    loadStats()
  }, [users, bikes, year]);


  function loadStats() {
    //Bikes:
    //console.log('Loading stats')
    let localStats = {};
    if (bikes) {
      localStats.bikesInTheSystem = bikes.filter(b => b.status === "Available" || b.status === "Donated" || b.status === "Reserved" || b.status === "Waiting for Release").length;
    }


    if (bikes && locations)
      if (bikes.length > 0 && locations.length > 0) {
        let localBikesDonatedPerBH = [];
        bikes.filter(b => b.status === "Available" || b.status === "Donated" || b.status === "Reserved" || b.status === "Waiting for Release").forEach(b => {
          let locName = locations.filter(loc => loc.id === b.location)[0].name
          //console.log(locName)
          
          if (localBikesDonatedPerBH[locName])
            localBikesDonatedPerBH[locName] += 1;
          else
            localBikesDonatedPerBH[locName] = 1;
          localBikesDonatedPerBH = Object.fromEntries(Object.entries(localBikesDonatedPerBH).sort())
          localStats.bikesDonatedPerBH = localBikesDonatedPerBH;
        }
        )
      };

    if (bikes && locations)
      if (bikes.length > 0 && locations.length > 0) {
        let LbikesDeliveredPerBH = [];
        bikes.filter(b => b.status === "Donated").forEach(b => {
          let locName = locations.filter(loc => loc.id === b.location)[0].name
          //console.log(locName)
          
          if (LbikesDeliveredPerBH[locName])
            LbikesDeliveredPerBH[locName] += 1;
          else
            LbikesDeliveredPerBH[locName] = 1;
          LbikesDeliveredPerBH = Object.fromEntries(Object.entries(LbikesDeliveredPerBH).sort())
          localStats.bikesDeliveredPerBH = LbikesDeliveredPerBH;
        }
        )
      };

    if (bikes)
      localStats.bikesAvailable=(bikes.filter(b => b.status === "Available").length);

    //find all the bikes available by location:
    localStats.bikesAvailablePerBH = [];
    if (bikes && locations)
      if (bikes.length > 0 && locations.length > 0) {
        bikes.filter(b => b.status === "Available").forEach(b => {
          let locName = locations.filter(loc => loc.id === b.location)[0].name
          //console.log(locName)
          
          if (localStats.bikesAvailablePerBH[locName])
            localStats.bikesAvailablePerBH[locName] += 1;
          else
            localStats.bikesAvailablePerBH[locName] = 1;
          localStats.bikesAvailablePerBH = Object.fromEntries(Object.entries(localStats.bikesAvailablePerBH).sort())
        })};
    
    let LsizesOfBikesAvailable = [];
    if (localStats.bikesAvailable) {
      bikes.filter(b => b.status === "Available").forEach(b => {

        if (LsizesOfBikesAvailable[b.size])
          LsizesOfBikesAvailable[b.size]++
        else
          LsizesOfBikesAvailable[b.size] = 1;

      })
      localStats.sizesOfBikesAvailable = Object.fromEntries(Object.entries(LsizesOfBikesAvailable).sort((a, b) => b[1] - a[1]));
    }

    if (bikes)
      localStats.bikesDelivered=(bikes.filter(b => b.status === "Donated").length);

    let LsizesOfBikesDelivered = [];
    if (localStats.bikesDelivered) {
      bikes.filter(b => b.status === "Donated").forEach(b => {
        if (LsizesOfBikesDelivered[b.size])
          LsizesOfBikesDelivered[b.size]++
        else
          LsizesOfBikesDelivered[b.size] = 1;
      })
      localStats.sizesOfBikesDelivered = Object.fromEntries(Object.entries(LsizesOfBikesDelivered).sort((a, b) => b[1] - a[1]))
    }

    if (bikes)
      localStats.bikesReserved = bikes.filter(b => b.status === "Reserved").length;
    if (bikes && locations)
      if (bikes.length > 0 && locations.length > 0) {
        let LbikesReservedPerBH = [];
        bikes.filter(b => b.status === "Reserved").forEach(b => {
          let locName = locations.filter(loc => loc.id === b.location)[0].name
          //console.log(locName)
          
          if (LbikesReservedPerBH[locName])
            LbikesReservedPerBH[locName] += 1;
          else
            LbikesReservedPerBH[locName] = 1;
          LbikesReservedPerBH = Object.fromEntries(Object.entries(LbikesReservedPerBH).sort())
          localStats.bikesReservedPerBH = LbikesReservedPerBH;
        })};
    
    //finds all the bikes reserved ordered by size:
    if (bikes) {
      let LbikesReservedPerSize = [];
      bikes.filter(b => b.status === "Reserved").forEach(b => {
        if (LbikesReservedPerSize[b.size])
          LbikesReservedPerSize[b.size] += 1;
        else
          LbikesReservedPerSize[b.size] = 1;
      }
      )
      localStats.bikesReservedPerSize = (Object.fromEntries(Object.entries(LbikesReservedPerSize).sort((a, b) => b[1] - a[1]))
      )
    }



    if (bikes)
      localStats.bikesWaiting = bikes.filter(b => b.status === "Waiting for Release").length;
    //finds all the bikes waiting for release for each location:
    if (bikes && locations)
      if (bikes.length > 0 && locations.length > 0) {
        let LbikesWaitingPerBH = [];
        bikes.filter(b => b.status === "Waiting for Release").forEach(b => {
          let locName = locations.filter(loc => loc.id === b.location)[0].name
          //console.log(locName)
          
          if (LbikesWaitingPerBH[locName])
            LbikesWaitingPerBH[locName] += 1;
          else
            LbikesWaitingPerBH[locName] = 1;
          LbikesWaitingPerBH = Object.fromEntries(Object.entries(LbikesWaitingPerBH).sort())
          localStats.bikesWaitingPerBH = LbikesWaitingPerBH;
        }
        )
      };
    //find all the bikes waiting for release for each bike size:
    if (bikes) {
      let LbikesWaitingPerSize = [];
      bikes.filter(b => b.status === "Waiting for Release").forEach(b => {
        if (LbikesWaitingPerSize[b.size])
          LbikesWaitingPerSize[b.size] += 1;
        else
          LbikesWaitingPerSize[b.size] = 1;
      }
      )
      localStats.bikesWaitingPerSize = (Object.fromEntries(Object.entries(LbikesWaitingPerSize).sort((a, b) => b[1] - a[1])))
    }

    if (users){
      console.log('Users: ', users.length)
      console.log('New Users: ', newUsers.length)
      
      localStats.numberOfRecipients = (users.filter(u => u.type !== "admin" && u.type !== "superAdmin" && u.status !== "Deleted").length);
    }

    if (users) {
      localStats.numberOfFemales = users.filter(u => u.gender === "Female" && u.type !== "admin" && u.type !== "superAdmin" && u.status !== "Deleted").length;
      localStats.numberOfMales = users.filter(u => u.gender === "Male" && u.type !== "admin" && u.type !== "superAdmin" && u.status !== "Deleted").length;
      localStats.numberOfOthers = users.filter(u => u.gender !== "Female" && u.gender !== "Male" && u.type !== "admin" && u.type !== "superAdmin" && u.status !== "Deleted").length;
    }
    localStats.percentageFemales = Math.round(localStats.numberOfFemales / localStats.numberOfRecipients * 100);
    localStats.percentageMales = Math.round(localStats.numberOfMales / localStats.numberOfRecipients * 100);
    localStats.percentageOthers = Math.round(localStats.numberOfOthers / localStats.numberOfRecipients * 100);
    

    let maxAge = 14;

    if (users) {
      localStats.numberOfChildrenF = users.filter(u => u.gender === "Female" && u.type !== "admin" && u.type !== "superAdmin" && u.status !== "Deleted" && u.age <= maxAge).length;
      localStats.numberOfChildrenM = (users.filter(u => u.gender === "Male" && u.type !== "admin" && u.type !== "superAdmin" && u.status !== "Deleted" && u.age <= maxAge).length);
      localStats.numberOfChildrenO = (users.filter(u => u.gender !== "Female" && u.gender !== "Male" && u.type !== "admin" && u.type !== "superAdmin" && u.status !== "Deleted" && u.age <= maxAge).length);
    }


    if (users) {
      localStats.adultFemalesWithBikes = (users.filter(u => u.gender === "Female" && u.bike_id && u.type !== "admin" && u.type !== "superAdmin" && u.status !== "Deleted" && u.age > maxAge).length);
      localStats.adultMalesWithBikes = (users.filter(u => u.gender === "Male" && u.bike_id && u.type !== "admin" && u.type !== "superAdmin" && u.status !== "Deleted" && u.age > maxAge).length);
      localStats.adultOtherWithBikes = (users.filter(u => u.gender !== "Female" && u.gender !== "Male" && u.bike_id && u.type !== "admin" && u.type !== "superAdmin" && u.status !== "Deleted" && u.age > maxAge).length);
    }


    if (users) {
      localStats.childFemalesWithBikes = (users.filter(u => u.gender === "Female" && u.bike_id && u.type !== "admin" && u.type !== "superAdmin" && u.status !== "Deleted" && u.age <= maxAge).length);
      localStats.childMalesWithBikes = (users.filter(u => u.gender === "Male" && u.bike_id && u.type !== "admin" && u.type !== "superAdmin" && u.status !== "Deleted" && u.age <= maxAge).length);
      localStats.childOtherWithBikes = (users.filter(u => u.gender !== "Female" && u.gender!== "Male" && u.bike_id && u.type !== "admin" && u.type !== "superAdmin" && u.status !== "Deleted" && u.age <= maxAge).length);
    }


    //Bikes needed
    if (users && bikes) {
      localStats.bikesNeeded = (localStats.numberOfRecipients - localStats.adultFemalesWithBikes - localStats.adultMalesWithBikes - localStats.adultOtherWithBikes - localStats.childFemalesWithBikes - localStats.childMalesWithBikes - localStats.childOtherWithBikes);
    }

    if (users && bikes) {
      let usersNeeding = users.filter(u => u.type !== "admin" && u.type !== "superAdmin" && u.status !== "Deleted" && !u.bike_id)
      let LtypesOfBikesNeeded = [];
      usersNeeding.forEach(u => {
        if (LtypesOfBikesNeeded[u.bike_size])
          LtypesOfBikesNeeded[u.bike_size] += 1;
        else
          LtypesOfBikesNeeded[u.bike_size] = 1;
      }
      )

      localStats.typesOfBikesNeeded = (Object.fromEntries(Object.entries(LtypesOfBikesNeeded).sort((a, b) => b[1] - a[1])))
    }

    
    setAllStats(localStats);

  }

  let changeYearFilter = (e) => {
    
    setYear(e.target.value);
  }

  return (
    <>
      <section id="content" className="wrapper">
        <h3>Statistics</h3>
        <div className="yearChoice">
          <label htmlFor="year">Choose year:</label>
          <select id="year" value={year} onChange={changeYearFilter}>
            <option value="All">All</option>
            <option value="2024">2024</option>
            <option value="2023">2023</option>
          </select>
        </div>
        <div className="centering">
          <h3 className="statCategory">Bikes ({year})</h3>
          {bikes && locations && allStats? <>
            <p className="bold"># New Bikes in the system: {allStats.bikesInTheSystem}</p>
            {allStats.bikesDonatedPerBH? <p> LOCATIONS: {JSON.stringify(allStats.bikesDonatedPerBH).replaceAll('"', "").replaceAll(':', ": ").replaceAll(/[,]/g, " -- ").slice(1, -1)}</p>: null}
            
            <p className="bold"># Bikes available: {allStats.bikesAvailable}</p>

            {allStats.sizesOfBikesAvailable ? <p>SIZES: {JSON.stringify(allStats.sizesOfBikesAvailable).replaceAll(/[,]/g, " -- ").replaceAll('"', "").replaceAll(':', ": ").slice(1, -1)}</p> : null}
            {allStats.bikesAvailablePerBH? <p>LOCATIONS: {JSON.stringify(allStats.bikesAvailablePerBH).replaceAll('"', "").replaceAll(':', ": ").replaceAll(/[,]/g, " -- ").slice(1, -1)}</p>: null}
           
            <p className="bold"># Bikes reserved: {allStats.bikesReserved}</p>
            {allStats.bikesReservedPerSize ? <p>SIZES: {JSON.stringify(allStats.bikesReservedPerSize).replaceAll(/[,]/g, " -- ").replaceAll('"', "").replaceAll(':', ": ").slice(1, -1)}</p> : null}
            {allStats.bikesReservedPerBH? <p>LOCATIONS: {JSON.stringify(allStats.bikesReservedPerBH).replaceAll('"', "").replaceAll(':', ": ").replaceAll(/[,]/g, " -- ").slice(1, -1)}</p>: null}
            
            <p className="bold"># Bikes delivered: {allStats.bikesDelivered}</p>
            <p>SIZES: {allStats.sizesOfBikesDelivered ? (JSON.stringify(allStats.sizesOfBikesDelivered).replaceAll(/[,]/g, " -- ").replaceAll('"', "").replaceAll(':', ": ").slice(1, -1)) : null}</p>

            {allStats.bikesDeliveredPerBH? <p>LOCATIONS: {JSON.stringify(allStats.bikesDeliveredPerBH).replaceAll('"', "").replaceAll(':', ": ").replaceAll(/[,]/g, " -- ").slice(1, -1)}</p>: null}

            <p className="bold"># Bikes waiting for release: {allStats.bikesWaiting}</p>
            <p>SIZES: {allStats.bikesWaitingPerSize ? (JSON.stringify(allStats.bikesWaitingPerSize).replaceAll(/[,]/g, " -- ").replaceAll('"', "").replaceAll(':', ": ").slice(1, -1)) : null}</p>
            {allStats.bikesWaitingPerBH? <p>LOCATIONS: {JSON.stringify(allStats.bikesWaitingPerBH).replaceAll('"', "").replaceAll(':', ": ").replaceAll(/[,]/g, " -- ").slice(1, -1)}</p>: null}

          </> : "Calculating..."}

          <h3 className="statCategory">Users ({year})</h3>
          {users && allStats? <>
            <p className="bold"># New Users in the system: {newUsers.length}</p>
            <p className="bold"># Female users ({allStats.percentageFemales}%): {allStats.numberOfFemales - allStats.numberOfChildrenF} adults and {allStats.numberOfChildrenF} children</p>
            <p className="bold"># Male users ({allStats.percentageMales}%): {allStats.numberOfMales - allStats.numberOfChildrenM} adults and {allStats.numberOfChildrenM} children</p>
            <p className="bold"># Other users ({allStats.percentageOthers}%): {allStats.numberOfOthers - allStats.numberOfChildrenO} adults and {allStats.numberOfChildrenO} children</p>
            

            <p className="bold"># Females that got a bike (reserved or delivered): {allStats.adultFemalesWithBikes} adults and {allStats.childFemalesWithBikes} children</p>
            <p className="bold"># Males that got a bike (reserved or delivered): {allStats.adultMalesWithBikes} adults and {allStats.childMalesWithBikes} children</p>
            <p className="bold"># Others that got a bike (reserved or delivered): {allStats.adultOtherWithBikes} adults and {allStats.childOtherWithBikes} children</p>

            <h3 className="statCategory">Still Needing Bikes</h3>
            <p className="bold"># Users needing bikes:{allStats.bikesNeeded?allStats.bikesNeeded:null}</p>
            {allStats.typesOfBikesNeeded ? <p>SIZES: {JSON.stringify(allStats.typesOfBikesNeeded).replace(/[,]/g, " -- ").replaceAll('"', "").replaceAll(':', ": ").slice(1, -1)}</p> : null}
            <br/><br/>
           
          </> : "Calculating..."}
        </div>


      </section>
    </>
  )
}
export default Stats;