const TermsAndConditions = ({ user }) =>{
    return(

<div className="wrapper termsAndConditions" id="termsAndConditions">
<h3>Terms and conditions</h3>

<p>By agreeing to these Terms and Conditions, you are agreeing to abide by
    the stated Guidelines and accept the terms outlined in the
    <a href="https://docs.google.com/document/d/12IsZfnIHVchu0PjlnOYXysGPX1UthnuLl6yirTBpIPo/edit?usp=sharing"> Waiver</a>.
</p>

<h4>Guidelines</h4>

<ol>
    <li>If you are donating a bike, for sanitary purposes, clean the bike thoroughly before hand-off (handlebars,
        gear shifters, seat, etc).</li>
        
        <li>Everyone participating in this program, whether donating or receiving a bike, must follow current public health guidelines.</li>
            <li>This a free program and priority will be given to those living in Toronto.</li>
            <li>The person donating their bike will not be receiving monetary compensation.</li>
            <li>If you are new to cycling, we recommend you sign-up for a learn-to-ride program before using a bike.</li>

 </ol>

<h4>
    Data Privacy
</h4>
<p>Women’s Cycling Network (WCN)  collects information through the data typed by you in the Sign Up form, as well as bike selected, cancelled and delivered to you. This information is stored and is only accessible through administrative privileges. The website and the administrator will use the information for three purposes:
</p>
<ol>
    <li>Matching a donor bike to a recipient;</li>
    <li>Contacting people about WCN and preferred hub events if they requested this;</li>
    <li>Statistical analysis of the program made by the administrators. Any statistical analysis presented publicly will not include any name, e-mail, birthdate, phone number or postal code information. Statistical analysis presented publicly may include anonymised compilations of user information regarding gender, age, reason to bike, city region, bike size and frame type.</li>
</ol>

<p>If you have any questions, please email<a href="mailto:bikematchwcn@gmail.com"> bikematchwcn@gmail.com</a>.
</p>

<br /><br /><br /><br />
</div>

)
}
export default TermsAndConditions;