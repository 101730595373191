import { useState, useEffect } from 'react';
import Schedule from './Schedule.js';

const Print = ({ user, bikes, logout, sendEmail }) => {
    let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dez'];

    let [formData, setFormData] = useState([]);
    let [locations, setLocations] = useState([]);
    let [dates, setDates] = useState([]);
    let [loaded, setLoaded] = useState(false);

    function handleChangeInput(event) {
        loaded = false;
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        const name = target.name;

        let localData = { ...formData };
        //console.log('Target name: ', name)
        //console.log('Value: ', value)
        if (name === "location") {
            let iValue = parseInt(value);
            localData[name] = iValue;;
            localData.name = locations.filter(x => x.id === iValue)[0].name;
        } else {
            localData[name] = value;
        }

        //console.log(localData);
        setLoaded(false);
        setFormData(localData);
    }



    function loadLocations() {
        // Fetch all locations, then fetch all pickup slots
        // console.log("fetch locations...");
        fetch("/getAllLocations")
            .then(res => {
                let r = res;
                // console.log(r);
                return r.json();
            })
            .then(res => {
                let locations = res;
                // console.log(locations);
                const selectLocationBox = document.getElementById('location');
                selectLocationBox.innerHTML = '';
                let option = document.createElement("option");
                option.text = "-- Choose --";
                option.value = "choose";
                selectLocationBox.add(option);

                locations.forEach(d => {
                    let option = document.createElement("option");
                    option.value = d.id;
                    option.text = d.id + " - " + d.name;
                    selectLocationBox.add(option);
                })
                setLocations(locations);
            })
            .catch(
                e => {
                    //console.log("Error fetching locations:" + e)
                    alert("There was an error connecting to our server to load the locations. Please logout, login and try again. Error: " + e)
                    logout()
                }
            )

    }

    useEffect(() => {
        loadLocations();
    }, []);

    useEffect(() => {

        loaded = false;
        if (locations && user.type!="superAdmin") {
            if (locations.length>0){
            const location = user.location
            let localData = { ...formData };
            localData.location = user.location;
            localData.name = locations.filter(x => x.id === location)[0].name;
            setLoaded(false);
            setFormData(localData);
        }
    }
    }, [locations]);

    useEffect(() => {
        const selectDateBox = document.getElementById('date');
        selectDateBox.innerHTML = '';
        let option = document.createElement("option");
        option.text = "-- Choose --";
        option.value = "choose";
        selectDateBox.add(option);
        if (dates) {
            dates.forEach(d => {
                let option = document.createElement("option");
                option.text = d.day + '/' + months[d.month] + '/' + d.year;
                option.value = d.day + "/" + d.month + '/' + d.year;
                selectDateBox.add(option);
            })
        }
    }, [formData.location, locations, dates]);

    useEffect(() => {
        loadDates();
    }, [locations, formData.location]);


    function loadDates() {
        let localDates = { ...dates };
        // Fetch all pickup slots
        // console.log("fetch pickup slots...");
        fetch("/getAllPickupSlots")
            .then(res => {
                let r = res;
                //console.log(r);
                return r.json();
            })
            .then(res => {
                let slots = res;
                // console.log('Slots:', slots);
                // console.log('Location id:', formData.location)
                let slotsAtLocation = slots.filter(s => s.location_id === formData.location);
                //console.log('Slots at Location = ', slotsAtLocation)
                localDates = slotsAtLocation.map(s => {
                    //console.log('Start time: ',s.start_time);

                    let day = parseInt(s.start_time.split("/")[2])
                    let month = parseInt(s.start_time.split("/")[1]) - 1
                    let year = parseInt(s.start_time.split("/")[0])
                    //console.log("Date inside loadDates() -> ", day, '/', month, '/', year)
                    return {
                        day: day,
                        month: month,
                        year: year,
                        id: '' + day + month + year
                    };
                });
                // console.log('Local Dates', localDates)
                let keys = {};
                let localDatesFiltered = [];
                localDates.forEach(l => {
                    if (keys[l.id]) {
                        return
                    } else {
                        keys[l.id] = true
                        localDatesFiltered.push(l);
                    }
                })

                setDates(localDatesFiltered);
            })
            .catch(
                e => {
                    //console.log("Error fetching slots:" + e)
                    alert("There was an error connecting to our server to load the pickup slots. Please logout, login and try again. Error: " + e)
                    logout()
                }
            )
    }

    // function onlyUnique(value, index, self) {
    //     return self.indexOf(value) === index;
    // }


    function loadData() {
        if (formData.location && formData.date) {
            //console.log('formData:',formData);
            let loc = formData.location;
            //alert('LOCATION: '+loc);
            let day = formData.date.split('/')[0];
            let month = formData.date.split('/')[1];
            let year = formData.date.split('/')[2];
            //console.log('date in loadData() ->'+day+"/"+month+"/"+year)

            let info = {
                location_id: loc,
                day: day,
                month: month,
                year: year,
            }
            //console.log(info)
            fetch("/getSchedule", {
                method: "POST",
                body: JSON.stringify(info),
                headers: { "Content-Type": "application/json" }
            })
                .then(res => res.json())
                .then(response => {
                    let rows = response;
                    //console.log('Reservations: ', rows);
                    let localData = { ...formData }
                    localData.data = rows;
                    setLoaded(true);
                    setFormData(localData);
                });

        } else {
            alert('Choose a place and a date.')
        }
    }


    return (
        <main>
            <div className="content wrapper">
                <h3>Schedule</h3>
                <form className="wrapper">
                    {user.type === "superAdmin" ?
                        <>
                            <label htmlFor="locationBox">Location <small></small> :</label><br />
                            <select onChange={handleChangeInput} id="location" name="location" type="select" required value={formData.location ? formData.location : 'choose'}>
                                <option value="choose">-- Choose an option --</option>
                            </select>
                        </>
                        :
                        <>
                            <label htmlFor="locationBox">Location <small>(you can only see your location)</small></label><br />
                            <select disabled id="location" name="location" type="select" required value={user.location ? user.location : 'choose'}>
                                <option value="choose">-- Choose an option --</option>
                            </select></>
                    }

                    <label htmlFor="locationBox">Date* :</label><br />
                    <select onChange={handleChangeInput} id="date" name="date" type="select" required >
                        <option>-- Choose an option --</option>
                    </select>
                    <button type="button" onClick={loadData} className="otherButton">Load Data</button>


                    {loaded && formData.data ?
                        <Schedule location={formData.location} location_name={formData.name} date={formData.date} data={formData.data} user={user} loadData={loadData} sendEmail={sendEmail} />
                        : <h2>Select a Location and date</h2>}

                </form>
            </div>
        </main>
    )
}
export default Print;