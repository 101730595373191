import { useState, useEffect } from 'react';

// IMAGES:
import step from './images/step.png';
import horizontal from "./images/horizontal.png";
import sloped from "./images/sloped.png";
import hybrid from "./images/hybridBike.png";
import urban from "./images/urbanCityBike.png";
import mountain from "./images/mountainBike.png";
import road from "./images/roadBike.png";
import folding from "./images/foldingBike.png";
import fat from "./images/fatBike.png";
import noFileLoaded from "./images/noFileLoaded.png";
import bikeWheelSizeHelp from "./images/bikeWheelSizeHelpbig.png";
import markingsOnTire from "./images/markingsOnTire.png";
import bikeSizeHelp from "./images/bikeSizeHelpFinal.png";
import { toHaveDisplayValue } from '@testing-library/jest-dom/dist/matchers';
import { createImportSpecifier } from 'typescript';

const AddBike = ({ user, sendEmail, logout }) => {

  let [locations, setLocations] = useState([]);
  useEffect(() => {
    loadLocations();
  }, []);
  function loadLocations() {
    fetch("/getAllLocations")
      .then(res => {
        let response = res;
        //console.log(response);
        return (response.json());
      })
      .then(response => {
        let final = response;
        //console.log(final);
        setLocations(final);

      })

      .catch(
        e => {
          //console.log("Error:" + e)
          alert("There was an error connecting to our server to load the bikes. Could you logout, login and try again?")
          logout()
        }
      )
  }

  let [bikes, setBikes] = useState();
  useEffect(() => {
    loadBikes();
  }, []);

  function loadBikes() {
    let data = { email: 'all' };
    fetch("/getAllBikesNoPics", {
      method: "POST",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" }
    })
      .then(res => {
        let response = res;
        //console.log(response);
        return (response.json());
      })
      .then(response => {
        //console.log('APP.js Bikes:');
        //console.log(response);
        setBikes(response);
      })

      .catch(
        e => {
          //console.log("Error:" + e)
          alert("There was an error connecting to our server to load the bikes. Could you logout, login and try again?")
          logout()
        }

      )


  }

  useEffect(() => {
    loadLocationOptions();
  }, [locations]);
  function loadLocationOptions() {
    let locs = document.getElementById("location");
    locs.innerHTML = '';
    let option = document.createElement("option");
    option.text = "-- Choose --";
    locs.add(option);
    locs.selectedIndex = "0";
    if (locations.length > 0) {
      locations.forEach(l => {
        let option = document.createElement("option");
        option.text = l.id + " - " + l.name;
        option.value = l.id;
        locs.add(option);
      })
    }
  }

  const [showHelp, setShowHelp] = useState();

  function handleShowHelp(event) {

    if (showHelp)
      setShowHelp(false);
    else
      setShowHelp(true);
  }

  let [users, setUsers] = useState();
  useEffect(() => {
    loadUsers();
  }, []);
  function loadUsers() {
    let data = {};
    fetch("/getAllUsers", {
      method: "POST",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" }
    })
      .then(res => {
        let response = res;
        //console.log(response);
        return (response.json());
      })
      .then(response => {
        let final = response;
        // console.log(final);
        setUsers(final);

      })

      .catch(
        e => {
          //console.log("Error:" + e)
          alert("There was an error connecting to our server to load the users. Could you logout, login and try again?")
          logout();
        }
      )


  }


  function addBike() {
    //console.log('add a bike');
    //alert('add a bike');
    document.getElementById('errorBox').innerHTML = '';
    let newBike = { ...formData };
    let today = new Date();
    newBike.date_included = new Date().toISOString().split("T")[0].replace(/\-/g, '/');
    newBike.log = newBike.log ? newBike.log : "" + "Created by " + user.email + " [" + today + "]."
    //console.log(newBike);
    if (!newBike.status) 
      newBike.status = "Available";
    fetch('/addBike', {
      'method': 'POST',
      'headers': {
        'Content-Type': 'application/json'
      },
      'body': JSON.stringify(newBike)
    }).then(response => {
      if (response.status === 200) {
        alert('BIKE ADDED');

        //Reset the form:
        document.getElementById('picture').value = '';
        document.getElementById('outputImage').src = noFileLoaded;
        document.getElementById('description').value = '';
        let checkboxes = document.getElementsByName('type');
        checkboxes.forEach(box => box.checked = false);
        setFormData({});


      } else {
        // read the response body as plain text
        response.text().then(text => {
          alert('Error adding bike.' + text);
          //console.log(text);
          document.getElementById('errorBox').innerHTML = text;
        });
      }
    }).catch(error => {
      alert(error);
      //console.log(error);
    });
    return false;
  }

  function releaseAllBikes() {
    let location = document.getElementById('location').value
    if (location != '-- Choose --') {
      let location = parseInt(document.getElementById('location').value)
      let location_name = locations.filter(l => l.id == location)[0].name
      let bikesReleased = bikes.filter(b => b.status == "Waiting for Release" && b.location == location)
      let nBikesReleased = bikesReleased.length;
      if (nBikesReleased > 0) {

        let textBikes = ''
        for (let name of bikesReleased)
          textBikes += "\n -> " + name.tag
        let final_text = "Press OK to release " + nBikesReleased + " bike(s) and to start sending emails to the people registered:" + textBikes;
        let confirm_final = window.confirm(final_text);
        if (confirm_final) {
          //Release all bikes from this bikeHub that have the status of 'Waiting for Release'
          fetch('/releaseBikes', {
            'method': 'POST',
            'headers': {
              'Content-Type': 'application/json'
            },
            'body': JSON.stringify({ id: location, userId:user.email, bikes: bikesReleased })
          }).then(response => {
            if (response.status === 200) {
              loadBikes()
              alert('BIKES RELEASED');

            } else {
              // read the response body as plain text
              response.text().then(text => {
                alert('Error releasing bikes.' + text);
                //console.log(text);
                document.getElementById('errorBox').innerHTML = text;
              });
            }
          }).catch(error => {
            alert(error);
            //console.log(error);
          });
        }

      } else {
        alert('There are no bikes to be released.')
      }

    } else {
      alert('You must choose a location first!');
    }
  }

  let fileReader = new FileReader();
  //eslint-disable-next-line
  let filterType = /^(?:image\/bmp|image\/cis\-cod|image\/gif|image\/ief|image\/jpeg|image\/jpeg|image\/jpeg|image\/pipeg|image\/png|image\/svg\+xml|image\/tiff|image\/x\-cmu\-raster|image\/x\-cmx|image\/x\-icon|image\/x\-portable\-anymap|image\/x\-portable\-bitmap|image\/x\-portable\-graymap|image\/x\-portable\-pixmap|image\/x\-rgb|image\/x\-xbitmap|image\/x\-xpixmap|image\/x\-xwindowdump)$/i;
  fileReader.onload = function (event) {
    let image = new Image();

    image.onload = function () {
      //document.getElementById("original-Img").src=image.src;
      let canvas = document.createElement("canvas");
      let context = canvas.getContext("2d");

      let maxPixels = 600;
      if ((image.width > maxPixels) | (image.heigth > maxPixels)) {
        //let proportion = image.width / image.height;
        //console.log("proportion:" + proportion);
        let redux = image.width > image.height ? image.width / (maxPixels) : image.height / (maxPixels);
        canvas.width = image.width / redux;
        canvas.height = image.height / redux;
      } else {
        canvas.width = image.width;
        canvas.height = image.height;
      }
      context.drawImage(
        image,
        0,
        0,
        image.width,
        image.height,
        0,
        0,
        canvas.width,
        canvas.height
      );

      document.getElementById("outputImage").src = canvas.toDataURL();
      document.getElementById("outputImage").style.display = "block";
      let onlyPic = document.getElementById("outputImage").src.split(',')[1];
      formData.picture = onlyPic;
      //console.log(formData.picture);
    };
    image.src = event.target.result;
  };

  // function previewImage(event) {
  //   fileReader.onload = function (event) {
  //     test("tamanho da string: " + fileReader.result.length);
  //     if (fileReader.result.length > 1000000) {
  //       alert("This image is too big. Please upload a smaller image.");
  //     } else {
  //       document.getElementById("outputImage").src = fileReader.result;
  //       document.getElementById("outputImage").style.display = "block";
  //       bikeApp.userInputBikeimage = fileReader.result;
  //     }
  //   };
  //   fileReader.readAsDataURL(event.target.files[0]);
  //   test(event.target.files[0]);
  // }

  let loadImageFile = function () {
    let uploadImage = document.getElementById("picture");

    //check and retuns the length of uploded file.
    if (uploadImage.files.length === 0) {
      return;
    }

    //Is Used for validate a valid file.
    let uploadFile = document.getElementById("picture").files[0];
    if (!filterType.test(uploadFile.type)) {
      alert("Please select a valid image.");
      return;
    }

    fileReader.readAsDataURL(uploadFile);
  };

  const [formData, setFormData] = useState({});
  function handleChangeInput(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if (name === "location" || name === "wheel_size")
      value = parseInt(value);


    // console.log('handleChangeInput');
    //console.log(event.target);
    //console.log(value);

    let localData = { ...formData };
    localData[name] = value;
    if (user.type !== "superAdmin") localData.location = user.location;
    //console.log(localData);
    setFormData(localData);
  }

  return (
    <>
      <section id="content" className="wrapper">
        <h3>Add / Release bikes</h3>
        <button className="showHelp" type="button" onClick={handleShowHelp}>{showHelp ? "-" : "?"}</button>
        <div className="contentCard wrapper">
          <form>
            Before getting started:
            <ul>
              <li>Know the size, type, frame style and wheel size of the bike.</li>
              <li>Take a photo of the bike to upload, preferably with someone standing besides it.</li>
              <li>If you need more details about the information needed to add a bike, click on the help button (?) located on the right.</li>
            </ul>
            {user.type === "superAdmin" ?
              <>
                <label htmlFor="locationBox">Location <small>(superAdmin can choose any location)</small></label><br />
                <select onChange={handleChangeInput} id="location" name="location" type="select" width="100%" required value={formData ? formData.location : ''}>
                  <option>-- Choose an option --</option>
                </select>
              </>
              : <>
                <label htmlFor="locationBox">Location <small>(you can only add and release bikes from your location)</small></label><br />
                <select className="disabledSelect" disabled onChange={handleChangeInput} id="location" name="location" type="select" width="100%" required value={user.location ? user.location : ''}>
                  <option>-- Choose an option --</option>
                </select>
              </>
            }
            <button type="button" id="releaseBikesButton" onClick={releaseAllBikes} className="releaseAllBikesButton">
              Release all "Waiting for Release" Bikes for this Location
            </button>
            <br /><br />

            <label htmlFor="tag">Bike Tag*</label><small>(this is the tag you will physically attach to the bike)</small><br />
            <input type="text" onChange={handleChangeInput} id="tag" name="tag" width="100%" required value={formData.tag ? formData.tag : ''} />

            <br /><br />

            <label htmlFor="donor_name">Donor Name (optional)</label><br />
            <input type="text" onChange={handleChangeInput} id="donor_name" name="donor_name" width="100%" required value={formData.donor_name ? formData.donor_name : ''} />

            <br /><br />



            <label>Bike type*</label>
            <div className="bikeTypeBox">
              <div>
                <input onChange={handleChangeInput} type="radio" id="City" name="type" value="City" />
                <label htmlFor="City"> {showHelp ? <img alt="city bike" width="80%" src={urban} /> : "City/Urban"}</label>
              </div>

              <div>
                <input onChange={handleChangeInput} type="radio" id="Hybrid" name="type" value="Hybrid" />
                <label htmlFor="Hybrid"> {showHelp ? <img alt="hybrid bike" width="80%" src={hybrid} /> : "Hybrid"}</label>
              </div>

              <div>
                <input onChange={handleChangeInput} type="radio" id="Mountain" name="type" value="Mountain" />
                <label htmlFor="Mountain"> {showHelp ? <img alt="mountain bike" width="80%" src={mountain} /> : "Mountain"}</label>
              </div>

              <div>
                <input onChange={handleChangeInput} type="radio" id="Road" name="type" value="Road" />
                <label htmlFor="Road"> {showHelp ? <img alt="road bike" width="80%" src={road} /> : "Road"}</label>
              </div>

              <div>
                <input onChange={handleChangeInput} type="radio" id="Fold" name="type" value="Fold" />
                <label htmlFor="Fold"> {showHelp ? <img alt="folding bike" width="80%" src={folding} /> : "Fold"}</label>
              </div>

              <div>
                <input onChange={handleChangeInput} type="radio" id="Fat" name="type" value="Fat" />
                <label htmlFor="Fat"> {showHelp ? <img alt="fat bike" width="80%" src={fat} /> : "Fat"}</label>
              </div>
            </div>
            <div>

              <label htmlFor="frame">Bike frame type* </label><br />
              {showHelp ? <div id="frameTypeImages">
                <img className="frameTypeImages" src={step} alt="type of bike frames: step-through" />
                <img className="frameTypeImages" src={horizontal} alt="type of bike frames: horizontal " />
                <img className="frameTypeImages" src={sloped} alt="type of bike frames: sloped" />
              </div> : null}
              <select onChange={handleChangeInput} id="frame" name="frame" required value={formData.frame ? formData.frame : ''}>
                <option value="choose" defaultValue>-- Choose --</option>
                <option value="Sloped">Sloped</option>
                <option value="Horizontal">Horizontal</option>
                <option value="Step-through">Step-through</option>
                <option value="Folding">Folding</option>
              </select>


              <label htmlFor="wheel_size">Bike wheel size (optional)</label>
              {showHelp ?
                <div id="showHelpBikeWheelSize" >
                  <img className="frameTypeImages" alt="bike wheel size help" width="40%" src={bikeWheelSizeHelp} />
                  <img className="frameTypeImages" alt="bike wheel size markings on tire" width="30%" src={markingsOnTire} />
                </div> : null}
              <select onChange={handleChangeInput} id="wheel_size" name="wheel_size" required value={formData.wheel_size ? formData.wheel_size : 'na'}>
                <option value="na" defaultValue>-- Choose --</option>
                <option value="10">10''</option>
                <option value="12">12''</option>
                <option value="14">14''</option>
                <option value="16">16''</option>
                <option value="18">18''</option>
                <option value="20">20''</option>
                <option value="24">24''</option>
                <option value="26">26''</option>
                <option value="27">27''</option>
                {/* <option value="27.5">27.5''</option> */}
                <option value="700">29'' / 700c</option>
                {/* <option value="32">32''</option> */}
                {/* <option value="36">36''</option> */}
              </select>

              <div>
                <label htmlFor="size">Bike size*</label>
                {showHelp ?
                  <div id="showHelpBikeSize" name="showHelpBikeSize" >
                    <img id="helpBikeSize" alt="bike size help" src={bikeSizeHelp} />
                  </div> : null}
                <select onChange={handleChangeInput} id="size" name="size" required value={formData.size ? formData.size : ''}>
                  <option value="choose" defaultValue>-- Choose --</option>
                  <option value="Toddler">Toddler</option>
                  <option value="Kids12">Kids 12''</option>
                  <option value="Kids14">Kids 14''</option>
                  <option value="Kids16">Kids 16''</option>
                  <option value="Kids20">Kids 20''</option>
                  <option value="Kids24">Kids 24''</option>
                  <option value="Kids26">Kids 26''</option>
                  <option value="XSmall">XSmall</option>
                  <option value="Small">Small</option>
                  <option value="Medium">Medium</option>
                  <option value="Large">Large</option>
                  <option value="XLarge">XLarge</option>
                  <option value="XXLarge">XXLarge</option>
                  {/* <option value="DontKnow">I don't know, please contact me</option> */}
                </select>
              </div>

              <label htmlFor="status" id="status">Bike Status (Default is AVAILABLE. You don't have to release the bikes after you add them anymore.)</label><br />
              <select  onChange={handleChangeInput}  id="status" name="status" value={formData.status ? formData.status : ''}>
                <option value="choose" defaultValue>-- Choose --</option>
                <option value="Available">Available</option>
                <option value="Waiting for Release" >Waiting for Release</option>
                {/* <option value="For Parts Only">For Parts Only</option> */}
                {/* <option value="Needs Repair">Needs Repair</option> */}
                {/* <option value="Waiting for Approval">Waiting for Approval</option> */}
                {/* FOR EDIT MODE ONLY:  */}
                {/* <option value="Reserved">Reserved</option> */}
                {/* <option value="Donated">Donated</option> */}
                {/* <option value="Deleted">Deleted</option> */}
              </select>

              <div>
                <label htmlFor="picture" id="pictureText"> Bike Photo*</label>
                <input id="picture" type="file" onChange={loadImageFile} required />
                <br />

              </div>
              <img className="frameTypeImages" id="outputImage" alt="uploaded bike" src={noFileLoaded} />

              <div>
                <p id="describeBike">
                  <label htmlFor="description">Bike description, plus any extras. (basket, panniers, racks, locks, etc.)*</label><br />
                  <textarea onChange={handleChangeInput} id="description" name="description" >

                  </textarea>
                </p>

              </div>


            </div>

            <div className="wrapper centering">
              <p>
                <label id="errorBox" name="erroBox" className="errorBox"></label>
              </p>

              <button type="button" id="addBikeButton" onClick={addBike} className="otherButton">
                Add Bike
              </button>



            </div>

          </form>
        </div>


      </section>
    </>
  )
}
export default AddBike;