import { useState } from 'react';



const Row = ({ e, user, loadData, sendEmail }) => {


    function handleConfirmReservation(reservation) {

        if (window.confirm("Are you sure you want to confirm reservation for bike (" + reservation.tag + ")?")) {
            reservation.admin = user.email;
            reservation.log = user.log;
            //console.log('Reservation=', reservation)
            fetch('/confirmBikeReservation', {
                'method': 'POST',
                'headers': {
                    'Content-Type': 'application/json'
                },
                'body': JSON.stringify(reservation)
            }).then(response => {
                if (response.status === 200) {
                    alert('Reservation confirmed!');
                    loadData()
                    //window.location.reload();
                }
            })
        }


    }

    function handleCancelReservation(reservation) {
        if (window.confirm("Are you sure you want to cancel reservation for bike (" + reservation.tag + ")?")) {
            reservation.admin = user.email;
            reservation.log = user.log;
            //console.log('Reservation=', reservation)
            fetch('/cancelBikeReservation', {
                'method': 'POST',
                'headers': {
                    'Content-Type': 'application/json'
                },
                'body': JSON.stringify(reservation)
            }).then(response => {
                if (response.status === 200) {
                    alert('Reservation cancelled!');
                    let recipient = e.email;
                    let subject = "Your bike reservation has been cancelled";
                    let text = 'Hi ' + e.first_name + ' ' + e.last_name + '.' + "We have returned the bike you reserved to inventory to be available for others in need of a bike.";
                    let html = 'Hi ' + e.first_name + ' ' + e.last_name + '.' + "<p>We have returned the bike you reserved to inventory to be available for others in need of a bike.</p>";
                    let replyTo = "bikematchwcn@gmail.com";
                    sendEmail(recipient, subject, text, html, replyTo);

                    loadData()
                    //window.location.reload();
                }
            })
        }

    }

    //console.log('Inside Row', e);
    let time = e.start_time.split(' ')[1].split(':')[0] + ":" + e.start_time.split(' ')[1].split(':')[1];
    return (
        <tr>
            <td>{time}</td>
            <td>{e.first_name}</td>
            <td>{e.last_name}</td>
            <td>{e.email}</td>
            <td>{e.phone_number}</td>
            <td>{e.tag}</td>
            <td>{e.status === "Donated" ? "Delivered" : e.status}</td>
            <td className="buttonColumn">
                {e.status === "Reserved" ?
                    <>    <button type="button" className="finalConfirmButton" onClick={() => handleConfirmReservation(e)}>Confirm</button><br />
                        <button type="button" className="finalCancelButton" onClick={() => handleCancelReservation(e)}>Cancel</button>
                    </> : e.status === "Donated" ? ' Yes ' : e.status === "Available" ? ' Cancelled ' : null}
            </td>
        </tr>
    )
}

export default Row;